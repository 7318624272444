import React, { useEffect, useContext } from "react";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, TabContent, TabPane, Container, Row, Col } from "reactstrap";
//https://www.npmjs.com/package/react-multi-carousel
import Carousel from 'react-multi-carousel';
//import '../../assets/css/react-multi-carousel.css';
import 'react-multi-carousel/lib/styles.css';

import StarRatingComponent from 'react-star-rating-component';
import { GetAPI } from "../../API/APICalling";
//help https://www.npmjs.com/package/react-star-rating-component

import { GlobalContext } from '../../App';

// const projects =
//     [
//         {
//             guid: 1,
//             heading: "CONCEIRGE",
//             items: [
//                 {
//                     guid: 1,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 1",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 2,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 2",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 3,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 3",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 4,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 4",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }, {
//                     guid: 5,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 5",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 6,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 6",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }
//             ]
//         },
//         {
//             guid: 2,
//             heading: "CLEANING",
//             items: [
//                 {
//                     guid: 1,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 1",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 2,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "deep cleaning",
//                         name: "Bella Low 2",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 3,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 3",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 4,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 4",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }, {
//                     guid: 5,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 5",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 6,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 6",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }
//             ]
//         },
//         {
//             guid: 3,
//             heading: "FACILITIES",
//             items: [
//                 {
//                     guid: 1,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 1",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 2,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "deep cleaning",
//                         name: "Bella Low 2",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 3,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 3",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 4,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 4",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }, {
//                     guid: 5,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 5",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 },
//                 {
//                     guid: 6,
//                     directory: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                     name: "deep cleaning",
//                     feedback: {
//                         name: "Bella Low 6",
//                         profilesrc: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//                         location: "Telok Blangah(condo, The Carribean)",
//                         review: "Writer Aaron Mahnke launched his podcast '\Lore'\ in 2015 and it has gained critical acclaim in the time since, including earning Best of 2015 honors from iTunes. The audio program is now becoming a TV series as an anthology that, like the podcast, uncovers real-life",
//                         rating: 3,
//                     }
//                 }
//             ]
//         }
//     ];
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

function OurProject() {
    const globalContext = useContext(GlobalContext);
    const [section, setSection] = React.useState([]);
    const [activeTab, setActivetab] = React.useState('');
    const [projects, setProject] = React.useState([]);
    const [slider, setSlider] = React.useState([]);
    const [feedback, setFeedback] = React.useState([]);
    const [activeFb, setActiveFb] = React.useState('');

    useEffect(() => {
        const FnGetProjects = async () => {
            var result = await GetAPI(`home/projects`);
            if (result) {
                if (result.error) {
                    //globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    console.log(result.data)
                    setProject(result.data)
                    //group creating
                    let abc = result.data.map(item => {
                        return item.service
                    });
                    setSection([...new Set(abc)]);
                    setActivetab([...new Set(abc)][0]);
                }
            }
        }
        FnGetProjects();
    }, []);

    useEffect(() => {
        const result = projects.filter(item => {
            return (item.service === activeTab) && item
        });

        if (result.length > 0) {
            setSlider(result.map((item, index) => (
                <div key={index} className="overlay-container">
                    <img className="projectImage"
                        alt={item.projname}
                        src={item.imagefile}
                        onClick={e => {
                            e.preventDefault();
                            FnShowFeedback(item.projcode);
                        }}
                    ></img>
                    <div className={activeFb === item.projcode ? "overlay active" : "overlay"} >
                        <div className="overlay-text">{item.projname}</div>
                    </div>
                </div>
            )))

        }
    }, [activeTab, activeFb]);


    const FnShowFeedback = async (projcode) => {
        // const result = projects.filter(item => {
        //     return (item.guid === activeTab) && item
        // });
        // if (result.length > 0) {
        //     setFeedback(result[0].items.filter(item => {
        //         return (item.guid === guid) && item
        //     }))
        // }
        setActiveFb(projcode);

        var result = await GetAPI(`home/projects/${projcode}`);
        if (result) {
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                console.log(result.data)
                setFeedback(result.data);
            }
        }

    }

    return (
        <>
            {section.length > 0 &&
                <div className="cd-section" id="ourproject">
                    <div className="features-3 pt-0">
                        <Container fluid>
                            <Row>
                                <div className="ml-auto mr-auto">
                                    <div className="big-heading">Our Projects</div>
                                    <hr className="hr-small"></hr>
                                </div>
                            </Row>
                            <Row>
                                <Card className="mb-0 pb-0">
                                    <CardHeader
                                        style={{
                                            border: "0px"
                                        }}>
                                        <Nav
                                            className="nav-tabs-neutral justify-content-center d-flex"
                                            data-background-color="white"
                                            role="tablist"
                                            tabs
                                        >
                                            {
                                                section.map((item, index) => (
                                                    <NavItem key={index}>
                                                        <NavLink
                                                            className={activeTab === item ? "project-tab active" : "project-tab"}
                                                            href="#pablo"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setActivetab(item);
                                                            }}
                                                        >
                                                            {item}
                                                        </NavLink>
                                                    </NavItem>
                                                ))
                                            }

                                            {/* <NavItem>
                                            <NavLink
                                                className={pills === "1" ? "project-tab active" : "project-tab"}
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setPills("1");
                                                }}
                                            >
                                                CONCEIRGE
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={pills === "2" ? "project-tab active" : "project-tab"}
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setPills("2");
                                                }}
                                            >
                                                FACILITIES
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={pills === "3" ? "project-tab active" : "project-tab"}
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setPills("3");
                                                }}
                                            >
                                                CLEANING
                                        </NavLink>
                                        </NavItem>
                                    */}

                                        </Nav>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent
                                            className="text-center"
                                        // activeTab={"pills" + pills}
                                        >
                                            <TabPane>
                                                {/* <TabPane tabId="pills1"> */}
                                                {/* {
                                                projects.map((item = items, index) => (
                                                    <Row key={index}>
                                                        <div className="ml-auto mr-auto"
                                                            style={{ width: "100%" }}>
                                                            <Carousel
                                                                swipeable={true}
                                                                draggable={false}
                                                                showDots={true}
                                                                responsive={responsive}
                                                                ssr={false} // means to render carousel on server-side.
                                                                infinite={false}
                                                                arrows={true}
                                                                autoPlay={false}
                                                                autoPlaySpeed={1000}
                                                                keyBoardControl={true}
                                                                customTransition="transform .3s ease-in-out"
                                                                transitionDuration={500}
                                                                containerClass="carousel-container"
                                                                // removeArrowOnDeviceType={["mobile"]}
                                                                //  deviceType={"desktop"}
                                                                dotListClass="custom-dot-list-style"
                                                            // itemClass="carousel-item-padding-40-px"
                                                            >
                                                                {Items}
                                                            </Carousel>
                                                        </div>
                                                    </Row>
                                                ))
                                            } */}


                                                <Row>
                                                    <div className="ml-auto mr-auto"
                                                        style={{ width: "100%" }}>
                                                        <Carousel
                                                            swipeable={true}
                                                            draggable={false}
                                                            showDots={true}
                                                            renderDotsOutside={true}
                                                            responsive={responsive}
                                                            ssr={true} // means to render carousel on server-side.
                                                            infinite={false}
                                                            arrows={true}
                                                            autoPlay={false}
                                                            autoPlaySpeed={1000}
                                                            keyBoardControl={false}
                                                            customTransition="transform .3s ease-in-out"
                                                            transitionDuration={500}
                                                            containerClass="carousel-container"
                                                            // removeArrowOnDeviceType={["mobile"]}
                                                            //  deviceType={"desktop"}
                                                            dotListClass="custom-dot-list-style"
                                                        // itemClass="carousel-item-padding-40-px"
                                                        >
                                                            {slider}
                                                        </Carousel>
                                                    </div>
                                                </Row>

                                                {/* <Row>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.1.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.2.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.3.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.4.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.5.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.5.PNG')}
                                                    ></img>
                                                </Col>
                                            </Row> */}

                                            </TabPane>
                                            {/* <TabPane tabId="pills2">
                                            <Row>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.2.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.1.PNG')}
                                                    ></img>
                                                </Col>

                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.3.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.5.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.4.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.1.PNG')}
                                                    ></img>
                                                </Col>

                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="pills3">
                                            <Row>

                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.2.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.5.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.3.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.1.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.4.PNG')}
                                                    ></img>
                                                </Col>
                                                <Col className="p-0 ml-auto mr-auto" md="2" sm="4" xs="6">
                                                    <img className="projectImage"
                                                        alt="Whissh Image"
                                                        src={require('../../assets/img/tab1.1.PNG')}
                                                    ></img>
                                                </Col>

                                            </Row>
                                        </TabPane> */}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Container>
                    </div>
                </div>
            }

            {feedback.length > 0 &&
                <div className="cd-section" id="CustomerSatisfaction">
                    <Container fluid>
                        <Row>
                            <div className="pb-10 ml-auto mr-auto">
                                <p className="title-1">Customer satisfaction</p>
                            </div>
                        </Row>
                        {
                            feedback.map((item, index) => (
                                <Row key={index}>
                                    <Col xl="12" lg="12" md="12">
                                        <Row>
                                            <Col className="offset-xl-2 offset-lg-2 pr-0 text-center" xl="2" lg="2" md="3" sm="3" xs="3">
                                                <img className="CustomerSatisfaction"
                                                    alt={item.header1}
                                                    src={item.directory || require('../../assets/img/client-imgsample-1.jpg')}
                                                ></img>
                                            </Col>
                                            <Col xl="8" lg="8" md="9" sm="9" xs="9">
                                                <div>
                                                    <div>
                                                        <p className="title-2">{item.header1}</p>
                                                        <p className="title-3">{item.header2}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="offset-xl-4 offset-lg-4 offset-md-3" xl="8" lg="8" md="9" sm="12" xs="12">
                                        <div className="details">
                                            <div>
                                                <p className="description"><i className="quotes fa fa-quote-left pr-10"></i>{item.content} </p>
                                            </div>
                                            <div style={{ fontSize: 24 }}>
                                                <StarRatingComponent
                                                    name="rate1"
                                                    editing={false}
                                                    starCount={5}
                                                    value={4}
                                                    starColor={`#ffb400`} /* color of selected icons, default `#ffb400` */
                                                    emptyStarColor={`#cfd9e2`} /* color of non-selected icons, default `#333` */
                                                /> </div>
                                            <div>
                                                <button type="button" className="btn-round btn btn-info">TRY NOW</button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            ))
                        }

                    </Container>
                </div>
            }
        </>
    );
}

export default React.memo(OurProject);
