import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  // Card,
  Container,
  Row,
  Col
} from "reactstrap";

import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';


function EasyStepSection() {

  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/home-body-1`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);


  return (
    <>

      {/* {data &&
        <div className="cd-section" id="customer" dangerouslySetInnerHTML={{ __html: data.body }}></div>} */}
      {data.body &&
        <div>
          <div class="features-3 pt-0" style={{marginTop: "20px"}}>
            <div class="ml-auto mr-auto col-md-8">
              <h1 class="big-heading">Best Home and Office Cleaning Services in Singapore</h1>
              <h2 class="big-heading" id="best_home_h2">Home and Office Cleaning Services</h2>
                <div class="best_home_container">
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Home Cleaning Services.webp")} loading="lazy" /> 
                      <h3>Home Cleaning Services</h3>
                      <div class="best_home_description">Our home cleaning services include regular housekeeping sessions scheduled weekly, with the same dedicated crew assigned throughout the package. Our team ensures your home remains spotless and can also provide ironing and laundry services.</div>
                  </div>
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Deep Cleaning Services.webp")} loading="lazy" />
                      <h3>Deep Cleaning Services</h3>
                      <div class="best_home_description">We offer one-time deep cleaning services such as move-in, move-out, post-renovation, and spring cleaning, ideal for thorough property cleaning. Using EU Ecolabel certified solutions, we guarantee eco-friendly deep cleaning. As a one-stop service provider, we also offer curtain cleaning and disinfection services.</div>
                  </div>
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Office Cleaning Services.webp")} loading="lazy" /> 
                      <h3>Office Cleaning Services</h3>
                      <div class="best_home_description">For businesses, our office cleaning services Singapore ensure a pristine and professional workspace. Whissh can provide eco-friendly solutions if needed. Trust us for the best cleaning services Singapore has to offer.</div>
                  </div>
                </div>
              <h2 class="big-heading">Best Aircon Services, Property Management Services and Other Services</h2>
                <div class="best_home_container">
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Aircon Services 1.webp")} loading="lazy" /> 
                      <h3>Best Aircon Services</h3>
                      <div class="best_home_description">In addition to our home cleaning services, we offer aircon services using eco-friendly solutions for chemical cleaning. Trust us for the best aircon services Singapore has to offer.</div>
                  </div>
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Upholstery Services.webp")} loading="lazy" />
                      <h3>Property Management Services</h3>
                      <div class="best_home_description">As providers of the best property management services, you can come to us with any queries related to your property and let us know what services you require. Our dedicated customer service team will work to provide you with the best solution required for your property needs.</div>
                  </div>
                  <div class="best_home_section">
                      <img alt="" class="rounded" src={require("../../assets/img/homepage/Specialised Services.webp")} loading="lazy" /> 
                      <h3>Specialised Services</h3>
                      <div class="best_home_description">Our specialised services include disinfection, carpet cleaning for offices, and handyman tasks like lighting. Rely on our cleaning services Singapore businesses and individuals trust. We are dedicated to delivering top-notch property management services tailored to your needs.</div>
                  </div>
                </div>
              <h2 class="big-heading">An Eco-Friendly Facilities Management Company: Cleaning and Aircon Services</h2>
              <hr class="hr-large" />
              <span class="description">Whissh prides ourselves on being the 1st and only service provider in the industry to use Eco-Friendly cleaning products for our <a href="https://www.whissh.com.sg/deep-cleaning/">deep cleaning</a> and <a href="https://www.whissh.com.sg/aircon/">aircon services!</a><br></br><br></br>By popular demand, Whissh Eco-Friendly cleaning products are made available for sale as well<br></br><br></br></span>
              <div>
                {/* <img alt="Best Cleaning Services Singapore | Whissh Eco Friendly Cleaning Products" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Whissh_ECO_Friendly_Cleaning_Product2.jpg" loading="lazy" /> */}
                <img alt="Best Cleaning Services Singapore | Whissh Eco Friendly Cleaning Products" class="rounded" src={require("../../assets/img/homepage/Whissh_ECO_Friendly_Cleaning_Product2.webp")} loading="lazy" />
              </div>
            </div>
          </div>
          <div class="EasyStep-3">
            <div class="container">
              <div class="row">
                <div class="ml-auto mr-auto text-center col-md-8">
                  <h2 class="big-heading">How It Works In 3 Easy Steps!</h2>
                  <hr class="hr-large" /><p class="description">Seamless process for on-demand home service</p>
                </div></div><div class="row"><div class="col-md-4"><div class="info info-hover">
                  <div class="icon icon-success icon-circle"><img alt="1" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step1.png" loading="lazy" />
                  </div>
                  <div class="details">
                    <h3 class="title">Book easily online</h3>
                    <p class="description">Book online with instant confirmation &amp; we'll match you with a trusted, experienced house cleaner.</p>
                  </div>
                </div>
                </div>
                <div class="col-md-4"><div class="info info-hover"><div class="icon icon-primary icon-circle">
                  <img alt="2" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step2.png" loading="lazy" />
                </div>
                  <div class="details">
                    <h3 class="title">Confirm and pay securely</h3>
                    <p class="description">Select date, time and pay securely online by credit card.</p>
                  </div>
                </div>
                </div>
                <div class="col-md-4"><div class="info info-hover"><div class="icon icon-info icon-circle">
                  <img alt="3" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step3.png" loading="lazy" />
                </div>
                  <div class="details">
                    <h3 class="title">Get a 5 star cleaner</h3>
                    <p class="description">Your trusted service crew will arrive at your home on time. Every cleaner is friendly and reliable.</p>
                  </div>
                </div>
                </div>
              </div>
            </div>  
          </div>
          <div class="EasyStep-section features-8 section-image">
            {/* <img alt="Cleaning Services | Whissh Cleaning Services" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/1593110879.jxr" loading="lazy" /> */}
            <img alt="Cleaning Services | Whissh Cleaning Services" class="rounded" src={require("../../assets/img/homepage/1593110879.webp")} loading="lazy" />
          </div>
          <div class="ml-auto mr-auto col-md-8">
          <h2 class="big-heading" id="why_choose_h2">Why Choose Whissh Cleaning Services?</h2>
              <div class="best_home_container">
                <div class="best_home_section" id="why_choose_section">
                    <img alt="" class="rounded" src={require("../../assets/img/homepage/One-Stop Service Provider.webp")} loading="lazy" /> 
                    <h3>One-Stop Service Provider</h3>
                    <div class="best_home_description">Looking for the best cleaning services in Singapore? Our home cleaning services and office cleaning services go beyond just cleaning. As a one-stop service provider, we offer additional services like aircon maintenance and upholstery cleaning, minimizing the hassle of finding reliable service providers.</div>
                </div>
                <div class="best_home_section" id="why_choose_section">
                    <img alt="" id="why_choose_icon" class="rounded" src={require("../../assets/img/homepage/Service Assurance Programme.webp")} loading="lazy" />
                    <h3>Service Assurance Programme</h3>
                    <div class="best_home_description">Our Service Assurance Programme takes your feedback seriously, providing further incentives to our crew. This ensures that our cleaning services maintain high standards, making us the top choice for home and office cleaning services in Singapore.</div>
                </div>
                <div class="best_home_section" id="why_choose_section">
                    <img alt="" id="why_choose_icon" class="rounded" src={require("../../assets/img/homepage/Sustainability-Focused Company.webp")} loading="lazy" /> 
                    <h3>Sustainability-Focused Company</h3>
                    <div class="best_home_description">We are committed to sustainability. Our home cleaning services and office cleaning services offer EU Ecolabel cleaning solutions upon demand, which are safe for health and the environment. These solutions are approved and used by renowned 5-star hotel chains, ensuring you get the best while being eco-friendly.</div>
                </div>
              </div>
              </div>
        </div>
      }
    </>
  );
}

export default EasyStepSection;
