import React, { useEffect, useState, useContext } from "react";
import {
  Row, Col
} from "reactstrap";
import "./OurServicesDetails_components.css"

export function OurServicesDetails_AirconPrice() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">Our Prices</h2>
          </div>
        </Col>
      </Row>
      <table className="rate-table">
        <tr>
          <th>Base Service</th>
          <th>Price</th>
        </tr>
        <tr>
          <td>Aircon Normal Servicing<br />(Wall-mounted, Portable, Window)</td>
          <td>1 Fancoil Unit - $45.00<br />2 Fancoil Units - $60.00<br />3 Fancoil Units - $75.00</td>
        </tr>
        <tr>
          <td>Aircon Normal Servicing<br />(Ceiling Cassette, Ceiling Suspend, Ceiling Ducted)</td>
          <td>1 Fancoil Unit - $60.00<br />2 Fancoil Units - $90.00<br />3 Fancoil Units - $120.00</td>
        </tr>
      </table>
      <br />
      <table className="rate-table">
        <tr>
          <th>Add-on Services</th>
          <th>Price</th>
        </tr>
        <tr>
          <td>Chemical Wash<br />(Wall-mounted, Portable, Window)</td>
          <td>$90 per unit</td>
        </tr>
        <tr>
          <td>Chemical Wash<br />(Ceiling Cassette, Ceiling Suspend, Ceiling Ducted)</td>
          <td>$180 per unit</td>
        </tr>
        <tr>
          <td>Chemical Overhaul (Small)<br />Typically used in bedrooms (&lt;22K BTU)<br />(Wall-mounted, Portable, Window)</td>
          <td>$160 per unit</td>
        </tr>
        <tr>
          <td>Chemical Overhaul (Big)<br />Typically used in sitting rooms (22K-24K BTU) (Wall-mounted, Portable, Window)</td>
          <td>$180 per unit</td>
        </tr>
        <tr>
          <td>Gas Top Up (Small)</td>
          <td>$120</td>
        </tr>
        <tr>
          <td>Gas Top Up (Large)</td>
          <td>$160</td>
        </tr>
      </table>
      <br />
      <hr />
    </>
  )
}

export function OurServicesDetails_AirconWhyChooseUs() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">Why Choose Whissh Aircon Servicing Services?</h2>
          </div>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center' }}>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>High Quality Solutions Used</h3>
          <div class="description">Choosing Whissh Aircon Servicing Services in Singapore ensures you receive the best aircon servicing available. Our eco-friendly solutions are safe for both human health and the aircon coils, making us a top choice for aircon servicing Singapore.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Digital Tracking System</h3>
          <div class="description">Our experienced technicians stay updated with the latest industry knowledge, ensuring high-quality service. With our digital tracking system, we recommend the most suitable service for each aircon unit.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Workmanship Warranty</h3>
          <div class="description">Plus, we offer a workmanship warranty, provided customers follow our technicians’ recommended services. Trust Whissh for reliable and efficient aircon servicing that prioritizes your health and satisfaction.</div>
        </Col>
      </Row>
      <br />
      <hr />
    </>
  )
}

export function OurServicesDetails_DCWhyChooseUs() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">Why Choose Whissh Deep Cleaning Services?</h2>
          </div>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center' }}>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Eco-Friendly</h3>
          <div class="description">Choosing Whissh Deep Cleaning Services in Singapore ensures you receive the best deep cleaning services Singapore has to offer. We use EU-Ecolabel certified cleaning solutions, making our services both effective and eco-friendly.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>One-Stop Service Provider</h3>
          <div class="description">As a one-stop service provider, we cater to all your needs, from curtain cleaning to aircon servicing and weekly housekeeping, eliminating the hassle of finding multiple service providers. Trust us for professional cleaning services Singapore residents rely on.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-star fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Quality Assurance Programme</h3>
          <div class="description">Our quality assurance program ensures that customer feedback is promptly addressed, with any issues rectified before the service concludes. Experience the convenience and reliability of our professional cleaners Singapore. Choose Whissh for a professional cleaner, cleaning house services today.</div>
        </Col>
      </Row>
      <br />
      <hr />
    </>
  )
}

export function OurServiceDetails_DCHowitworks() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">How our Deep Cleaning Services Work?</h2>
            <p class="description text-center">When you schedule a deep cleaning service with us, you can expect a seamless process.</p>
          </div>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center' }}>
        <Col md="4">
          <i class="fa fa-laptop fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Step 1 - Book Online</h3>
          <div class="description">Book online and receive a confirmation email once your booking is confirmed. Select the date and time of your choice, and include any requests in your booking. Our Customer Service Team will reach out as required.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-mobile fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Step 2 – Reminder SMS</h3>
          <div class="description">A reminder SMS will be sent to you the day before your service.</div>
        </Col>
        <Col md="4">
          <i class="fa fa-thumbs-up fa-2x" aria-hidden="true" style={{ color: "#404e58e0" }}></i>
          <h3 class="title-3" style={{ marginBottom: "0", paddingTop: "10px", paddingBottom: "10px" }}>Step 3 – Return to a Cleaned Property</h3>
          <div class="description">Our professional cleaners Singapore will handle everything, so you do not need to present throughout the session. Upon returning, you’ll find a thoroughly cleaned property. Experience the best deep cleaning services Singapore has to offer with our reliable and efficient professional cleaning services Singapore. Trust our professional cleaner team for all your cleaning house needs.</div>
        </Col>
      </Row>
      <br />
      <hr />
    </>
  )
}

export function OurServiceDetails_AirconTestimonial() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">Your Testimonials</h2>
          </div>
        </Col>
      </Row>
      <div class="home_testimonial-container">
        <div class="home_testimonial">
          <p class="testimonial_client-name">A Loyal Customer</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>"I engaged Whissh team for aircon servicing. Their service team arrived ahead of the appointment time, was thorough in their job and provided good advice to me on how to keep my aircon in good working condition. Overall, their service is very professional."</p>
        </div>
        <div class="home_testimonial">
          <p class="testimonial_client-name">Happy Customer</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>Engaged Whissh for Aircon servicing - chemical overhaul for our 3 units. Great service by both the technician and customer support. Happy with the serviced air-cons. They also use eco-friendly solutions, which is a plus!"</p>
        </div>
        <div class="home_testimonial">
          <p class="testimonial_client-name">A First-time Customer</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>"The service provided by Whissh included:</p>
          <p style={{ fontSize: "0.8em" }}>1. Deep Cleaning of the apartment</p>
          <p style={{ fontSize: "0.8em" }}>2. Aircon servicing</p>
          <p style={{ fontSize: "0.8em" }}>3. Deep cleaning of the curtains</p>
          <p style={{ fontSize: "0.8em" }}>The experience was efficient & effective! Kudos goes to Apple who was very responsive patient & effective in carrying out her job. Thank you! We also want to single out the indian lady that did a first class job in deep cleaning of the apartment for handling taking over!"</p>
        </div>
      </div>
      <br/>
      <hr/>
    </>
  )
}

export function OurServiceDetails_DCTestimonial() {
  return (
    <>
      <Row>
        <Col md="12">
          <div className="mt-20">
            <h2 className="title-2 text-center">Your Testimonials</h2>
          </div>
        </Col>
      </Row>
      <div class="home_testimonial-container">
        <div class="home_testimonial">
          <p class="testimonial_client-name">A Concerned Cat Owner</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>"I got the spring cleaning package and the cleaners were very thorough and detailed and my place looks good as new! They even vacuumed all of my cat’s soft furnishings and took care when cleaning the windows to make sure my cat’s safely in the room which I really appreciated. I highly recommend it!"</p>
        </div>
        <div class="home_testimonial">
          <p class="testimonial_client-name">First Time Customer</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>Cleaning crew are punctual for spring cleaning. The job is done superbly well and I like that their cleaning products are eco-friendly. Highly recommended."</p>
        </div>
        <div class="home_testimonial">
          <p class="testimonial_client-name">A Busy Professional</p>
          <div class="home_stars">★★★★★</div>
          <p style={{ fontSize: "0.8em" }}>"The ladies were punctual and they were very thorough in their service. Their standard of cleanliness exceeded my rental agent expectations, who had seen a lot of post rental cleaning. I was able to handover my rental place without remarks on cleanliness. Will definitely engage them again."</p>
        </div>
      </div>
      <br/>
      <hr/>
    </>
  )
}
