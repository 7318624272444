import React, { useContext } from "react";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";


export default function MBActiveDetails(props) {
    // const [startDate, setStartDate] = useState();
    // const [timeSlot, setTimeSlot] = useState("1");
    // const [modal, setModal] = useState(false);
    // const [showCrewLocation, setShowCrewLocation] = useState(false);
    const bdContext = useContext(BookingDetailsContext);

    return (
        <>
            <div className="card">
                {bdContext.BDState.BookingDetails &&
                    <React.Fragment>
                        {
                            bdContext.BDState.BookingDetails.length < 1 ?
                                <div colSpan="4" className="text-center empty-screen">No Records Found</div>
                                :
                                <div className="card-body">
                                    <div>
                                        <div className="text-center">
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/user-default.png")}
                                                style={{ maxHeight: "125px" }}
                                            ></img>
                                        </div>
                                        <div className="text-center title-2">
                                            {bdContext.BDState.BookingDetails[0].crewname}
                                        </div>
                                        <div className="text-center">
                                            <span className="btn-link btn btn-info p-0"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({ type: 'COMPONENT', ComponentType: 'BOOKING_ACTIVE_DETAILS' });
                                                }}
                                            ><b>BACK TO BOOKING DETAILS</b></span>
                                        </div>
                                    </div>
                                    <div className="table-responsive table-list-view" id="myBookingDetailsTable">
                                        <div className="mapouter">
                                            <div className="gmap_canvas">
                                                <iframe id="gmap_canvas" src={`https://maps.google.com/maps?q=${bdContext.BDState.BookingDetails[0].lat},${bdContext.BDState.BookingDetails[0].longi}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                                                    style={{
                                                        border: "0",
                                                        width: "100%",
                                                        height: "320px"
                                                    }}
                                                >
                                                </iframe>
                                                {/* <a href="https://www.crocothemes.net"></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </React.Fragment>
                }
            </div>

        </>
    )
}