import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
// core components
// import OtherCustomerServicePackage from "components/PackageList/OtherCustomerServicePackage.js"
import { GlobalContext } from '../App.js';
import { GetAPI } from '../API/APICalling';

export default function Paynowqr() {
    const globalContext = useContext(GlobalContext);
    const history = useHistory();
    // const [isLoading, setIsLoading] = useState(true);
    const [sor, setSor] = useState('');
    const [data, setData] = useState({});
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (history.location.state !== undefined) {
            // setIsSuccess(true);
            if (history.location.state.sor !== '')
                setSor(history.location.state.sor);
            //setIsLoading(false)
        }
        else {
            // setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchCMS = async () => {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            let url = `cms/paynow`
            if (history.location.state.coy == '3Y')
                url = `cms/paynow_3Y`
            var result = await GetAPI(url);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.data) {
                    setData(result.data.content)
                }
            }
        }
        fetchCMS();
    }, []);


    return (
        <>

            <div id="PaymentSuccessfull" className="adjust-navbar">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" xl="10" lg="10" md="12" sm="12" xs="12">
                            <Row className="pb-15">

                                <Col md="12" className="ml-auto mr-auto ">
                                    <div className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="header-icon"
                                            src={require("../assets/img/icon-login.png")}
                                        ></img>
                                    </div>
                                    {sor ? (
                                        <React.Fragment>
                                            <div className="text-center">
                                                <h2 className="big-heading p-5">Order Has Been Received !</h2>
                                                <b className="title-3 p-10">You have Successfully completed your booking.</b><br />
                                                <p className="description p-10">Your SOR: {sor}</p>
                                            </div>
                                            <hr className="hr-large"></hr>
                                            {!globalContext.loaderState.isLoading &&
                                                <div className="text-center">
                                                    <Row>
                                                        <Col xl="4" lg="4" md="4" sm="12" xs="12" className="text-left">
                                                            {data &&
                                                                <img
                                                                    alt="paynow"
                                                                    src={data.qrcode}
                                                                    className="mb-10"
                                                                >
                                                                </img>
                                                            }
                                                        </Col>
                                                        <Col xl="8" lg="8" md="8" sm="12" xs="12">
                                                            {data && data.instruction && data.instruction.length > 0 &&
                                                                data.instruction.map((item, index) => (
                                                                    <p className="text-left" key={index}>
                                                                        <span className="pr-10 font-weight-bold">{item.header}</span>
                                                                        <span className="description">{item.content}</span>
                                                                    </p>
                                                                ))
                                                            }
                                                            {/* <p className="text-left"><span className="pr-10 font-weight-bold">Step 1:</span><span className="description">Log in to your bank’s mobile banking app.</span></p>
                                                            <p className="text-left"><span className="pr-10 font-weight-bold">Step 2:</span><span className="description">Scan the QR code using your bank’s app.</span></p>
                                                            <p className="text-left"><span className="pr-10 font-weight-bold">Step 3:</span><span className="description">Enter the amount to pay if it’s not generated, then confirm the transfer.</span></p>
                                                            <p className="text-left"><span className="pr-10 font-weight-bold">Note :</span><span className="description">Please key in this reference number at remark when making Paynow transaction: <b>{sor}</b>.</span></p>
                                                     */}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            <Button
                                                className={"btn btn-round btn-info"}
                                                to="/my-account/my-booking" tag={Link}
                                            >
                                                VIEW BOOKING
                                                 </Button>
                                        </React.Fragment>
                                    ) :
                                        (
                                            <React.Fragment>
                                                <div className="text-center">
                                                    <h2 className="big-heading p-5">Order has been not created !</h2>
                                                    <b className="title-3 p-10">Please try again..</b>
                                                    {/* <b className="title-3 p-10">Your Transaction Id: {txnId}</b> */}
                                                </div>
                                                <div className="text-center">
                                                    <Link
                                                        className="btn btn-round btn-default"
                                                        to={{
                                                            pathname: '/service-booking',
                                                            state: { 'redirect': 'FINALIZE' }
                                                        }}
                                                    >
                                                        My Cart
                                                        </Link>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </Col>

                            </Row>
                            {/* <OtherCustomerServicePackage para={{ title: "Customers who booked your service also booked these" }} /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
