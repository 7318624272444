import React, { useEffect, useState, useContext, useReducer } from "react";
import { Container, Row, Col, Button, Label, FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from "react-router-dom";
import { DateFormat } from "../../utils/common";
import { BlogInitialState, BlogReducer } from "../../reducer/HomeReducer";
import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
// as an array
// const treeData = [
//     {
//         key: 'Whissh Tips',
//         label: 'Whissh Tips',
//         // ..., // any other props you need, e.g. url
//         nodes: [
//             {
//                 key: '2020',
//                 label: '2020',
//                 nodes: [
//                     {
//                         key: '6',
//                         label: 'June',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                     {
//                         key: '7',
//                         label: 'July',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                     {
//                         key: '8',
//                         label: 'August',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                 ],
//             }
//         ],
//     },
//     {
//         key: 'Newsletter',
//         label: 'Newsletter',
//         nodes: [
//             {
//                 key: '2019',
//                 label: '2019',
//                 nodes: [
//                     {
//                         key: '10',
//                         label: 'October',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                     {
//                         key: '11',
//                         label: 'November',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                     {
//                         key: '12',
//                         label: 'December',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     },
//                 ],
//             },
//             {
//                 key: '2020',
//                 label: '2020',
//                 nodes: [
//                     {
//                         key: '6',
//                         label: 'June',
//                         nodes: [] // you can remove the nodes property or leave it as an empty array
//                     }
//                 ],
//             }
//         ],
//     },
// ];

export default function Blog() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(BlogReducer, BlogInitialState)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const FnGetBlogs = async () => {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`home/blogs`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    dispatch({ type: 'GET_SUCCESS', payload: result.data });
                }
            }
        }
        FnGetBlogs();
    }, []);

    const noRefCheck = async (e) => {
        var key = e.key.split('/');
        if (e.level === 0) {
            dispatch({ type: 'FILTER', category: key[0] })

        }
        else if (e.level === 1) {
            dispatch({ type: 'FILTER_YEAR', category: key[0], year: key[1] })
        }
        else if (e.level === 2) {
            dispatch({ type: 'FILTER_MONTH', category: key[0], year: key[1], month: key[2] })

        }
    }

    return (
        <>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                 Blog
                             </li>
                        </ol>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h1 className="big-heading">Blog</h1>
                            <hr className="hr-large"></hr>
                            {/* <p className="description">
                                A Seamless process for on-demand home service
                            </p> */}
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="12" className="text-left">
                            <Label className="title-3">Category</Label>
                        </Col>
                        <Col md="3" sm="12" xs="12">
                            <FormGroup>

                                 <select className="form-control"
                                    style={{
                                        textAlignLast: "center"
                                    }}
                                    onChange={e =>
                                        dispatch({
                                            type: 'FILTER_RESULT',
                                            value: e.currentTarget.value,
                                        })
                                    }>
                                    <option value="">All</option>
                                    {state.categoryddl.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md="3">
                            {/* <TreeMenu data={treeData}
                                debounceTime={125}
                                disableKeyboard={false}
                                onClickItem={noRefCheck}
                                resetOpenNodesOnDataUpdate={false}
                                hasSearch={false}
                            />
                            <Button className="btn btn-info btn-block"
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch({ type: 'RESET' })
                                }}> Reset
                                  </Button> */}

                            <TreeMenu data={state.LeftMenu}
                                debounceTime={125}
                                disableKeyboard={false}
                                onClickItem={noRefCheck}
                                resetOpenNodesOnDataUpdate={false}
                                hasSearch={false}>
                                {({ items, resetOpenNodes }) => (
                                    <div>
                                        {/* <button onClick={resetOpenNodes} /> */}

                                        <Button className="btn btn-info btn-block"
                                            onClick={e => {
                                                resetOpenNodes();
                                                e.preventDefault();
                                                dispatch({ type: 'RESET' })
                                            }}> Reset
                                     </Button>

                                        {defaultChildren({ items })}
                                        <Button className="btn btn-block btn-tree"
                                            onClick={e => {     
                                                resetOpenNodes();                                          
                                                e.preventDefault();
                                                dispatch({ type: 'FACEBOOK' })                                               
                                            }}> Our Facebook
                                     </Button>
                                    </div>
                                )}
                            </TreeMenu>

                            {/* <TreeMenu
                                data={treeData}
                                onClickItem={({ key, label, ...props }) => {
                                    this.navigate(props.url); // user defined prop
                                }}
                                initialActiveKey='first-level-node-1/second-level-node-1' // the path to the active node
                                debounceTime={125}>
                                {({ search, items }) => (
                                    <>
                                        <Input onChange={e => search(e.target.value)} placeholder="Type and search" />
                                        <ListGroup>
                                            {items.map(props => (
                                                // You might need to wrap the third-party component to consume the props
                                                // check the story as an example
                                                // https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
                                                <ListGroupItem {...props} />
                                            ))}
                                        </ListGroup>
                                    </>
                                )}
                            </TreeMenu> */}

                        </Col>
                        <Col md="9">
                            <Row>
                                {state.FilterBlogList &&
                                    state.FilterBlogList.length !== 0 &&
                                    state.FilterBlogList.map((item, index) => (
                                        <div key={index} className="col-md-6 col-sm-6">
                                            <div className="blog-content blog-block">
                                                <Link to={item.pageurl === "" ? "#" : `${item.pageurl}`}>
                                                    <div className="blog-cover-img">
                                                        <img src={item.imageurl} alt={item.title} />
                                                    </div>
                                                </Link>
                                                <div className="blog-text">
                                                    <span className="blog-date">{DateFormat(item.blogDate, 'DD-MMM-YYYY')}</span>
                                                    <Link to={item.pageurl === "" ? "#" : `${item.pageurl}`}>
                                                        <h4>{item.title}</h4>
                                                    </Link>
                                                    {/* <p className="blog-desc description">
                                                Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                            </p> */}

                                                    <p className="blog-desc description" dangerouslySetInnerHTML={{ __html: item.summary }}></p>
                                                    <Link className="btn btn-info" to={item.pageurl === "" ? "#" : `${item.pageurl}`}>
                                                        Read More
                                            </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Row>
                            
                                {state.FacebookPlugin &&
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fwhissh.sg%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=198855676850664" width="340" height="500"  scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                }
                            
                        </Col>

                        {/*                         
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="blog-content blog-block">
                                <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                    <div className="blog-cover-img"><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" /></div>
                                </a>
                                <div className="blog-text">
                                    <span className="blog-date">03 January, 2019</span>
                                    <a href="/blog/things-to-remember-while-applying-to-canadian-universities">
                                        <h4>Whissh Bathroom Cleaning</h4>
                                    </a>
                                    <p className="blog-desc description">
                                        Our professional cleaning specialists are equally adept at cleaning residential houses and commercial offices. Our single purpose is to have the occupants enjoy a clean, fresh and hygienic living environment, in which they can harness their energies and fulfil their hopes and ambitions!
                                </p>
                                    <a className="btn btn-info" href="/blog/things-to-remember-while-applying-to-canadian-universities">Read More</a>
                                </div>
                            </div>
                        </div>
                    */}

                    </Row>
                </Container>
            </div>
        </>
    );
}
