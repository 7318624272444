import React, { useContext, useEffect, useState } from "react";
import { FormGroup, Row, Col, Button, ModalBody, ModalHeader, Input, Modal } from "reactstrap";
import StarRatingComponent from 'react-star-rating-component';
//help https://www.npmjs.com/package/react-star-rating-component

import { useHistory } from "react-router-dom";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { PostAPI } from "../../API/APICalling";
import { GetAPI } from "../../API/APICalling";
import TopUpModal from "../../components/MyAccount/TopUpModal";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from "../../constant";


export default function ModalMBSubmitFeedback() {
    const bdContext = useContext(BookingDetailsContext);
    const globalContext = useContext(GlobalContext);
    const history = useHistory();
    const [availableCredit, setAvailableCredit] = useState(0);
    const [modal, setModal] = useState(false);
    const [stripePromise, setstripePromise] = useState(null);
    // const [newProperty, setNewProperty] = useState("0");
    // const [tip, setTip] = useState("0");


    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (bdContext.BDState.BookingDetails.length > 0) {
            if (bdContext.BDState.BookingDetails[0].refnum) {
                bdContext.BDDispatch({ type: 'FEEDBACK_FIELD', field: 'refnum', value: bdContext.BDState.BookingDetails[0].refnum });
                setstripePromise(loadStripe(STRIPE_PUBLIC_KEY));
                // Create an scoped async function in the hook
                // async function CallGetCreditFunction() {
                //     const avlblCredits = await FnGetAvailableCredit()
                //     setAvailableCredit(avlblCredits || 0);
                // }
                // Execute the created function directly
                FnGetAvailableCredit();
            }
            else {
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
            }
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
        }
    }, []);

    const FnGetAvailableCredit = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`mycredit/total/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setAvailableCredit(result.data || 0);
                    return result.data;
                }
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const session = getData('session');
        const avlblCredits = await FnGetAvailableCredit();
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        if (parseFloat(avlblCredits || 0) < parseFloat(bdContext.BDState.feedback.tipamt || 0)) {
            globalContext.notifications('error', 'You do not have enough credits left in your account to give a tip.');
        }
        else {
            if (!bdContext.BDState.feedback.rating || bdContext.BDState.feedback.rating === 0) {
                globalContext.notifications('error', 'Please Select rating.');
                return false
            }
            if (bdContext.BDState.feedback.refnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`mybooking/details/feedback/${bdContext.BDState.feedback.refnum}`, bdContext.BDState.feedback
                );
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        bdContext.BDDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Feedback submitted successfully.');
                        bdContext.BDDispatch({ type: 'FEEDBACK_SUCCESS' });
                        bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
                    }
                }
            }
        }

    }

    const IsSelected = (e) => {
        var result = bdContext.BDState.feedback.items.filter(item => {
            return item === e
        });
        if (result.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };

    return (
        <>
            <div className="submit-feedback-modal">
                <div className="col-md-11">
                    <ModalHeader toggle={() => bdContext.BDDispatch({ type: 'CLOSE_MODAL' })}>
                    </ModalHeader>
                </div>
                <ModalBody className="p-0 text-center">
                    <div className="card col-md-10">
                        <div className="card-header text-center title-2">
                            Send us your feedback
                         </div>
                        <div className="card-body">
                            <Row>
                                <Col md="12">
                                    <p className="title-3">Rate your crew</p>
                                    <div className="ml-auto mr-auto" style={{ fontSize: 50, lineHeight: "1" }}>
                                        <StarRatingComponent
                                            name="rating"
                                            editing={true}
                                            starCount={6}
                                            value={bdContext.BDState.feedback.rating}
                                            starColor={`#B8850A`}
                                            emptyStarColor={`#FFE4C4`}
                                            // onStarClick={onStarClick.bind(this)}
                                            onStarClick={e => {
                                                bdContext.BDDispatch({
                                                    type: 'FEEDBACK_FIELD',
                                                    field: 'rating',
                                                    value: e
                                                })
                                            }}
                                        /> </div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md="12" className="text-left">
                                    <Row>
                                        <Col xs="6" className="text-left">
                                            <p className="title-3">Enjoyed your service?</p>
                                            <p className="description">Consider giving our crew a tip</p>

                                        </Col>
                                        <Col xs="6" className="text-right">
                                            <p className="mb-10">
                                                <span className="description pr-10"> Available Credits:</span>
                                                <span className="title-3 pr-10"> S$ {availableCredit}</span>
                                            </p>
                                            <span className="btn-link btn btn-info p-0"

                                                onClick={e => {
                                                    e.preventDefault();
                                                    setModal(true);
                                                }}
                                            ><b>TOP-UP CREDITS</b></span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                            <div
                                                className={bdContext.BDState.feedback.tipamt === 1 ? "topup-box active" : "topup-box"}
                                                // onClick={e => {
                                                //     e.preventDefault();
                                                //     setTip("1");
                                                // }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_TIPAMT',
                                                        value: 1
                                                    })
                                                }}> $1.00 </div>
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                            <div className={bdContext.BDState.feedback.tipamt === 2 ? "topup-box active" : "topup-box"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_TIPAMT',
                                                        value: 2
                                                    })
                                                }}> $2.00 </div>
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                            <div
                                                className={bdContext.BDState.feedback.tipamt === 5 ? "topup-box active" : "topup-box"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_TIPAMT',
                                                        value: 5
                                                    })
                                                }}> $5.00</div>
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                            <div className={bdContext.BDState.feedback.tipamt === 10 ? "topup-box active" : "topup-box"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_TIPAMT',
                                                        value: 10
                                                    })
                                                }}> $10.00</div>
                                        </Col>
                                    </Row>
                                    <p className="title-3">Overall experience</p>
                                    <p className="description">Let your service crew know too!</p>
                                    <Row>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("clean") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "clean"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample1.png")}
                                                ></img>
                                                <b>Clean & Comfy</b>
                                            </div>
                                        </Col>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("thorough") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "thorough"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample2.png")}
                                                ></img>
                                                <b>Throrough & Meticulous</b>
                                            </div>
                                        </Col>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("helpful") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "helpful"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample3.png")}
                                                ></img>
                                                <b>Helpful & Approachable</b>
                                            </div>
                                        </Col>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("eco") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "eco"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample4.png")}
                                                ></img>
                                                <b>Eco-friendly Products</b>
                                            </div>
                                        </Col>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("safe") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "safe"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample5.png")}
                                                ></img>
                                                <b>Safe & Careful</b>
                                            </div>
                                        </Col>
                                        <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                            <div
                                                className={IsSelected("professional") ? "btn-feedback active" : "btn-feedback"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    bdContext.BDDispatch({
                                                        type: 'FEEDBACK_ITEMS',
                                                        value: "professional"
                                                    })
                                                }}>
                                                <img
                                                    alt="Whissh Image"
                                                    src={require("../../assets/img/icon-badge-sample6.png")}
                                                ></img>
                                                <b>Professional & Pleasant</b>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <Input type="textarea" className="form-control" placeholder="Write remarks"
                                                    maxLength={500}
                                                    value={bdContext.BDState.feedback.remark}
                                                    onChange={e => {
                                                        e.preventDefault();
                                                        bdContext.BDDispatch({
                                                            type: 'FEEDBACK_FIELD',
                                                            field: 'remark',
                                                            value: e.currentTarget.value
                                                        })
                                                    }} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-footer text-center">
                            <Button
                                disabled={bdContext.BDState.feedback.rating > 0 ? false : true}
                                onClick={e => {
                                    e.preventDefault();
                                    // props.Function.FnCloseModal();
                                    onSubmit(e);
                                }}
                                className={"btn btn-round btn-info"}
                            >
                                SUBMIT
                              </Button>
                        </div>
                    </div>
                </ModalBody>

                {modal ? (
                    <Modal size="md" isOpen={modal}
                        className="transparent-modal"
                    >
                        <Elements stripe={stripePromise}>
                            <TopUpModal Function={{ setModal: setModal, CallBackFunction1: FnGetAvailableCredit }} />
                        </Elements>
                    </Modal>
                ) : null
                }
            </div>
        </>
    )
}