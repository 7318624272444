import React, { useContext, useEffect } from 'react';
import { Form, Label, Button, ModalBody, Row, Col, Container } from 'reactstrap';
import { GlobalContext } from '../../App.js';


function ModalChooseLocationNewUser(props) {
    const globalContext = useContext(GlobalContext);
    const [newProperty, setNewProperty] = React.useState("1");
    // const [modalChooseLocationNewUser, setModalChooseLocationNewUser] = React.useState(false);
    // const OpenChooseLocationNewUserModal = (modal) => {
    //     console.log("OpenChooseLocationNewUserModal called");
    //     setModalChooseLocationNewUser(!modal);
    // }
    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <>
            <div className="choose-location-modal pt-80">
                <ModalBody>
                    <Container className="first-half">
                        <Row>
                            <div className="left-side">
                                <div className="text-left">
                                    <b className="title-2">Splendid!</b>
                                    <p className="description">You have selected us to guide you find the best service that suits you. Please help us in answering a couple of questions more
                                                     in order for us to assess your needs properly.</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <img
                                    alt="Whissh Image"
                                    src={require("../../assets/img/avatar-butler.png")}
                                    className="butler"
                                ></img>
                            </div>
                        </Row>
                    </Container>

                    <Container className="second-half">
                        <Row>
                            <hr style={{ width: "45%" }} className="mb-20"></hr>
                            <Col md="12" className="ml-auto mr-auto text-center">
                                <p className="description">What is you property type?</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center  ">
                                <Button
                                    className={newProperty === "1" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                    onClick={e => {
                                        e.preventDefault();
                                        setNewProperty("1");
                                    }}>
                                    <img
                                        alt="Whissh Image"
                                        src={require("../../assets/img/icon-property-landed.png")}
                                    ></img>
                                    <b>Landed</b>
                                </Button>
                            </Col>
                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                <Button
                                    className={newProperty === "2" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                    onClick={e => {
                                        e.preventDefault();
                                        setNewProperty("2");
                                    }}>
                                    <img
                                        alt="Whissh Image"
                                        src={require("../../assets/img/icon-property-landed.png")}
                                    ></img>
                                    <b>Apartment</b>
                                </Button>
                            </Col>
                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                <Button
                                    className={newProperty === "3" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                    onClick={e => {
                                        e.preventDefault();
                                        setNewProperty("3");
                                    }}>
                                    <img
                                        alt="Whissh Image"
                                        src={require("../../assets/img/icon-property-landed.png")}
                                    ></img>
                                    <b>Others</b>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="ml-auto mr-auto">
                                <Form>
                                    <div className="form-group text-center">
                                        <Label>What is the property size in sqft?</Label>
                                        <input type="text" className="form-control text-center" placeholder="Enter Property Size" />
                                    </div>
                                    <div className="form-group  text-center">
                                        <Label>No. of bedroom</Label>
                                        <select className="form-control" placeholder="Select bedroom"
                                            style={{
                                                textAlignLast: "center"
                                            }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                    </div>
                                    <div className="form-group  text-center">
                                        <Label>No. of bathroom</Label>
                                        <select className="form-control" placeholder="Select bathroom"
                                            style={{
                                                textAlignLast: "center"
                                            }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <hr style={{ width: "100%" }}></hr>
                            <Col md="12" className="ml-auto mr-auto text-center">
                                <a href="/new-customer-package">
                                    <button type="button" className="btn btn-round btn-info"
                                        onClick={() => globalContext.headerDispatch({ type: 'CLOSE' })}
                                    >SCHEDULE ASSESSMENT</button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </div>
        </>
    );
}

export default ModalChooseLocationNewUser;