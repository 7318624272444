import React, { useContext, useEffect, createContext, useReducer } from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../App';
import PickDateTime from '../components/Booking/PickDateTime.js'
import BookingInformation from '../components/Booking/BookingInformation.js'
import FinalizeBooking from '../components/Booking/FinalizeBooking.js'
import CheckoutPayment from '../components/Booking/CheckoutPayment.js'

import ModalPackageDetails from "../components/Booking/ModalPackageDetails.js";
import ModalPackageAddOns from "../components/Booking/ModalPackageAddOns.js";
import { ServiceBookingReducer, ServiceBookingInitialState, BookingReducer, BookingInitialState, COReducer, COInitialState } from "../reducer/bookingReducer";
import { getData } from "../utils/localStorage";
import { FnCreateProductList } from "../utils/ObjectListFormat";
import ModalLaundryPackage from "../components/Booking/ModalLaundryPackage";

export const ServiceBookingContext = createContext();


function ServiceBooking() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(ServiceBookingReducer, ServiceBookingInitialState);
    const [bState, bDispatch] = useReducer(BookingReducer, BookingInitialState);
    const [COState, CODispatch] = useReducer(COReducer, COInitialState);
    const history = useHistory();
    // const [BackCount, SetBackCount] = useState(0);
    // useBeforeunload(() => alert("You'll lose your data!"));




    // if (window.event) {
    //     if (window.event.clientX < 40 && window.event.clientY < 0) {
    //         alert("back button is clicked");
    //     } else {
    //         alert("refresh button is clicked");
    //     }
    // } else {
    //     // want some condition here so that I can differentiate between
    //     // whether refresh button is clicked or back button is clicked.

    // }

    useEffect(() => {

        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
            // console.log('inside')
        });
        // window.addEventListener('unload', function (e) {
        //     debugger;
        //     e.preventDefault();
        //     alert('bye');
        // }, false);

        const session = getData('session');
        if (!history.location.state) {
            globalContext.notifications('error', 'Sorry, You can not access this page directly, Please try again.');
            history.push("/");           
        }
        else if (!session) {
            //globalContext.notifications('error', 'Your session has been expired, Please login again.');
            //history.push("/");
            if (history.location.state.redirect !== 'FINALIZE') {
                var productDetails = {
                    baseamt: parseFloat(history.location.state.baseamt || 0),
                    jobnum: history.location.state.jobnum || '',
                    jobnum2: history.location.state.jobnum2 || '',
                    matname: history.location.state.matname || '',
                    matnum: history.location.state.matnum || '', 
                }
              
                const transferredData = getData('transferredData');
                transferredData && transferredData.map(function (item) { 
                    if (item.key === "bathroomqty") {
                        productDetails.shipbathroomQty = parseInt(item.value || 0);
                    } else if (item.key === "bedroomqty") {
                        productDetails.shipbedroomQty = parseInt(item.value || 0);
                    } else if (item.key === "housetype") {
                        productDetails.shiphousetype = item.value;
                    } else if (item.key === "locationid") {
                        productDetails.shiplocationid = item.value;
                    } else if (item.key === "area") {
                        productDetails.shipsize = parseInt(item.value || 0);
                    }
                });

                bDispatch({ type: 'SET_DATA', payload: FnCreateProductList([productDetails], '')[0] });
                dispatch({ type: 'DATE_TIME', payload: FnCreateProductList([productDetails], '')[0] });
            }
            else
            {
                globalContext.notifications('error', 'Your session has been expired, Please login again.');
                history.push("/");
            }
        }
        else {
            if (history.location.state.redirect === 'FINALIZE') {
                dispatch({ type: 'FINALIZE_BOOKING' });

            }
            else {
                const transferredData = getData('transferredData');
                const transferredAddon = getData('transferredAddon');
                // if (!transferredData) {
                //     globalContext.notifications('error', 'Oops ! Something went wrong.');
                //     history.push("/");
                // }
                // else {
                var addonList = [];

                var productDetails = {
                    baseamt: parseFloat(history.location.state.baseamt || 0),
                    jobnum: history.location.state.jobnum || '',
                    jobnum2: history.location.state.jobnum2 || '',
                    matname: history.location.state.matname || '',
                    matnum: history.location.state.matnum || '',
                    shipfirstname: session.firstname || '',
                    shiplastname: session.lastname || '',
                    shipphone: session.mobile || '',
                    shipemail: session.email || '',
                    addonList: addonList
                }
                transferredAddon && transferredAddon.length > 0 && transferredAddon.forEach(ite => {
                    addonList.push(ite)
                });

                //binding all state value from local storage
                transferredData && transferredData.map(function (item) {
                    // if (item.key === "addr1") {
                    //     productDetails.shipaddr1 = item.value;
                    // } else if (item.key === "addr2") {
                    //     productDetails.shipaddr2 = item.value;
                    // } else if (item.key === "addr3") {
                    //     //  productDetails.shipaddr3 = item.value;
                    // } else 
                    if (item.key === "bathroomqty") {
                        productDetails.shipbathroomQty = parseInt(item.value || 0);
                    } else if (item.key === "bedroomqty") {
                        productDetails.shipbedroomQty = parseInt(item.value || 0);
                    } else if (item.key === "housetype") {
                        productDetails.shiphousetype = item.value;
                    } else if (item.key === "locationid") {
                        productDetails.shiplocationid = item.value;
                    } else if (item.key === "area") {
                        productDetails.shipsize = parseInt(item.value || 0);
                    }

                    //  else if (item.key === "postcode") {
                    //     productDetails.shippostcode = item.value;
                    // } else if (item.key === "area") {
                    //     productDetails.shipsize = parseInt(item.value || 0);
                    // } else if (item.key === "unitno") {
                    //     productDetails.shipunitno = item.value;
                    // }
                });

                bDispatch({ type: 'SET_DATA', payload: FnCreateProductList([productDetails], '')[0] });
                if (history.location.state.redirect === 'BOOKING_INFORMATION')
                {
                    dispatch({ type: 'BOOKING_INFORMATION', payload: FnCreateProductList([productDetails], '')[0] });
                }
                else
                {
                dispatch({ type: 'DATE_TIME', payload: FnCreateProductList([productDetails], '')[0] });
                }
                // }
            }
        }
    }, []);



    const FnReturnComponent = (param) => {
        // console.log('param:  ' + param)
        switch (param) {
            case 'BOOKING_INFORMATION':
                return <BookingInformation />
            case 'FINALIZE_BOOKING':
                return <FinalizeBooking />
            case 'CHECKOUT_PAYMENT':
                return <CheckoutPayment />
            case 'DATE_TIME':
                return <PickDateTime />
            // etc...
        }
    }
    const FnReturnModalContent = (param) => {
        // console.log('param:  ' + param)
        switch (param) {
            case 'PACKAGE_DETAILS':
                return <ModalPackageDetails />
            case 'PACKAGE_ADD_ONS':
                return <ModalPackageAddOns />
            case 'MLT_PACKAGE_ADD_ONS':
                return <ModalLaundryPackage />
        }
    }


    return (
        <>
            <ServiceBookingContext.Provider value={{
                ServiceState: state,
                ServiceDispatch: dispatch,
                BookingState: bState,
                BookingDispatch: bDispatch,
                CheckoutState: COState,
                CheckoutDispatch: CODispatch
            }}>
                <div id="ServiceBooking" className="adjust-navbar">
                    <Container fluid>
                        <Row>
                            <Col className="ml-auto mr-auto pt-10 pb-10" xl="10" lg="12" md="12">
                                <div className="text-center">
                                    <h2 className="big-heading mb-10 p-0">Service Booking</h2>
                                    <hr className="hr-large"></hr>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" xl="8" lg="10" md="12" sm="12">
                                <div className="multisteps-form__progress">
                                    <button
                                        className={
                                            (state.visibleComponentType === "DATE_TIME" ||
                                                state.visibleComponentType === "BOOKING_INFORMATION" ||
                                                state.visibleComponentType === "FINALIZE_BOOKING" ||
                                                state.visibleComponentType === "CHECKOUT_PAYMENT") ?
                                                "multisteps-form__progress-btn js-active" : "multisteps-form__progress-btn"
                                        }
                                        type="button" title="Date & Time">Date & Time
                                 </button>
                                    <button
                                        className={
                                            (
                                                state.visibleComponentType === "BOOKING_INFORMATION" ||
                                                state.visibleComponentType === "FINALIZE_BOOKING" ||
                                                state.visibleComponentType === "CHECKOUT_PAYMENT") ?
                                                "multisteps-form__progress-btn js-active" : "multisteps-form__progress-btn"
                                        }

                                        type="button" title="Booking Information">Booking Information
                                </button>
                                    <button
                                        className={
                                            (
                                                state.visibleComponentType === "FINALIZE_BOOKING" ||
                                                state.visibleComponentType === "CHECKOUT_PAYMENT") ?
                                                "multisteps-form__progress-btn js-active" : "multisteps-form__progress-btn"
                                        }
                                        type="button" title="Finalize Booking">Finalize Booking
                                </button>
                                    <button
                                        className={
                                            (
                                                state.visibleComponentType === "CHECKOUT_PAYMENT") ?
                                                "multisteps-form__progress-btn js-active" : "multisteps-form__progress-btn"
                                        }
                                        type="button" title="Checkout & Payment">Checkout & Payment
                                 </button>
                                </div>
                            </Col>
                        </Row>
                        {
                            FnReturnComponent(state.visibleComponentType)
                        }
                        {state.modal ? (
                            <Modal size={state.modalSize} isOpen={state.modal}
                                className="transparent-modal"
                            // toggle={() => setModal(!modal)}
                            >
                                {FnReturnModalContent(state.visibleModalContentType)}
                            </Modal>
                        ) : null
                        }
                    </Container>
                </div>
            </ServiceBookingContext.Provider>
        </>
    );
}

export default ServiceBooking;
