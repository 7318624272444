import React, { useEffect, useReducer, useContext } from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { GlobalContext } from '../../App';
import { useHistory } from "react-router-dom";
import { MyConatctListReducer, MyConatctListInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI, DeleteAPI } from "../../API/APICalling";

export default function MPMyContacts() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyConatctListReducer, MyConatctListInitialState)
    const history = useHistory();

    useEffect(() => {
       FnGetMyContacts();
    }, []);


    const FnGetMyContacts = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myaccount/mycontacts/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // console.log(result.data)
                    dispatch({ type: 'GET_SUCCESS', payload: result.data });
                }
            }
        }
    };


    const FnDeleteContact = async (guid) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (guid) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await DeleteAPI(`myaccount/mycontacts/${session.arnum}?guid=${guid}`);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        // dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Contact deleted successfully.');
                        FnGetMyContacts();
                    }
                }
            }
        }

    }

    return (
        <>
            <div className="card">
                <div className="card-header text-left">
                    <Row>
                        <Col md="8" sm="12" xs="12" className="title-2 myaccount-icon">
                            My Contacts
                        </Col>
                        <Col md="4" sm="12" xs="12" className="myaccount-icon  pull-right">
                            <FormGroup className="search">
                                <Input type="search" placeholder="Search.."
                                    value={state.searchString}
                                    onChange={e =>
                                        dispatch({
                                            type: 'FILTER_RESULT',
                                            field: 'searchString',
                                            value: e.currentTarget.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive table-list-view" id="myContact">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Phone</th>
                                    <th className="text-left">Email</th>
                                    <th className="text-left">Type</th>
                                    <th className="text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.FilterContacts &&
                                    <React.Fragment>
                                        {
                                            state.FilterContacts.length < 1 ?
                                                <tr>
                                                    <td colSpan="5" className="text-center empty-screen">No Records Found</td></tr>
                                                :
                                                <React.Fragment>
                                                    {
                                                        state.FilterContacts.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="text-left contactName">{item.firstname} {item.lastname}</td>
                                                                <td className="text-left">{item.mobile}</td>
                                                                <td className="text-left">{item.email}</td>
                                                                <td className="text-left contactType">{item.ctype}</td>
                                                                <td className="text-left">
                                                                    <span className="pr-10">
                                                                        <a href={`tel:${item.mobile}`}>
                                                                            <img
                                                                                alt="Whissh Image"
                                                                                className="rounded-circle"
                                                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                                                style={{ maxHeight: "18px", paddingRight: "10px" }}
                                                                            ></img>
                                                                        </a>
                                                                    </span>
                                                                    <span>
                                                                        <img
                                                                            alt="Whissh Image"
                                                                            className="rounded-circle"
                                                                            src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                                            style={{ maxHeight: "14px" }}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                FnDeleteContact(item.guid);
                                                                            }}
                                                                        ></img>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </React.Fragment>
                                        }
                                    </React.Fragment>
                                }

                                {/* <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            className="rounded-circle"
                                            src={require("../../assets/img/user-default.png")}
                                            style={{ maxHeight: "48px" }}
                                        ></img>
                                    </td>
                                    <td className="text-left contactName">Benjamin Chua</td>
                                    <td className="text-left">92304324</td>
                                    <td className="text-left">user@gmail.com</td>
                                    <td className="text-left">987654, 01 Street Name #01-01 Singapore</td>
                                    <td className="text-left contactType">Crew</td>
                                    <td className="text-left">
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-phone.png")}
                                                style={{ maxHeight: "20px", margin: "5px" }}
                                            ></img>
                                        </span>
                                        <span>
                                            <img
                                                alt="Whissh Image"
                                                className="rounded-circle"
                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                style={{ maxHeight: "14px" }}
                                            ></img>
                                        </span>
                                    </td>
                                </tr> */}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
