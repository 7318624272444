import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button, FormGroup, Label, Input, Form } from "reactstrap";
import { useHistory } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from "react-router-dom";
import Iframe from 'react-iframe'
import { DateFormat } from "../../utils/common";
import { ValidateEmail } from "../../utils/common";
import { getData } from "../../utils/localStorage";
import { Helmet } from 'react-helmet';

const commentList = [
    {
        name: "Whissh",
        date: DateFormat(new Date(), 'DD MMM YYYY h:mm a'),
        email: "Whissh@gmail.com",
        message: "Hey Admin, your blog post is very useful and informative, especially for those who are new in the blogging field and don’t know much about blog writing. Hey Admin, your blog post is very useful and informative, especially for those who are new in the blogging field and don’t know much about blog writing.",
    },
    {
        name: "Whissh",
        date: DateFormat(new Date(), 'DD MMM YYYY h:mm a'),
        email: "Whissh@gmail.com",
        message: "Hey Admin, your blog post is very useful and informative, especially for those who are new in the blogging field and don’t know much about blog writing. Hey Admin, your blog post is very useful and informative, especially for those who are new in the blogging field and don’t know much about blog writing. Hey Admin, your blog post is very useful and informative, especially for those who are new in the blogging field and don’t know much about blog writing.",
    }
]

export default function BlogDetails(props) {
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState('');
    const [formData, setFormData] = useState({});
    const [comments, setComment] = useState(commentList);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const FnGetBlogDetails = async () => {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`home/blogs/${props.match.params.id}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    console.log(result.data);

                    if (!result.data.content) { history.push("/blog"); }
                    else
                        setData(result.data)
                }
                else {
                    history.push("/blog");
                }
            }
        }
        FnGetBlogDetails();
    }, []);

    const FnSubmitComment = async (e) => {
        e.preventDefault();
        console.log(formData);
        var firstname = '', email = ''
        // if (!formData.name) {
        //     globalContext.notifications('error', 'Name is required.');
        //     return false;
        // }
        // if (!formData.email) {
        //     globalContext.notifications('error', 'Email address is required.');
        //     return false;
        // }
        // if (!ValidateEmail(formData.email)) {
        //     globalContext.notifications('error', 'You have entered an invalid email address.');
        //     return false
        // }
        const session = getData('session');
        if (!session) {
            return false;
        }
        if (!formData.message) {
            globalContext.notifications('error', 'Please provide some comments.');
            return false
        }

        else {
            if (formData) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                ///var result = await PostAPI(`home/corpenquiry`, formData);
                //if (result) {
                //globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                //    if (result.error) {
                //        globalContext.notifications('error', result.error);
                //    }
                //    else {

                setComment([...comments, {
                    name: session.firstname,
                    date: DateFormat(new Date(), 'DD MMM YYYY h:mm a'),
                    email: session.email,
                    message: formData.message
                }]);
                setFormData({});
                globalContext.notifications('success', 'Your request has been sumitted successfully.');
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                //  }
                //}
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{data.metatitle}</title>
                <meta name="description" content={data.metadescription} />
            </Helmet>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/blog">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    Blog
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                {data.title}
                            </li>
                        </ol>
                    </Container>
                </div>

                {!globalContext.loaderState.isLoading &&
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="12">
                                <Row className="pt-15">
                                    <Col md="8" className="ml-auto mr-auto text-center">
                                        <h1 className="big-heading">{data.title}</h1>
                                        <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-30">
                            <div className="col-md-12 card-content overflap-banner">
                                <div className="text-container" dangerouslySetInnerHTML={{ __html: data.content }}></div>
                                {/* <div className="text-container">

                                <div className="media-section">
                                    <div className="video-container">
                                        <Iframe src="//www.youtube.com/embed/NwlVemEDUi4?wmode=opaque" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Iframe>
                                    </div>
                                </div>

                                <div className="media-section">
                                    <div className="video-container">
                                        <iframe src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <p className="description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>

                                <p className="description">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                 </p>


                                <div className="detail-list">
                                    <h4 className="title-3">What is a tuition fees-?</h4>
                                    <p className="description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <p className="description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <p className="description">
                                        Step 1: Total course fees from your offer letter minus any eligible discounts <br />
                                     Step 2: In case of a previous deposit, deduct that amount from the result of the first step <br />
                                        Step 3: Pay the remaining
                                </p>
                                </div>
                                <div className="detail-list">
                                    <h4 className="title-3">Paying your tuition fees-</h4>
                                    <p className="description">
                                        For international students there are several options to pay for tuition viz.-
                        </p>
                                    <p className="description">
                                        • Hard cash- <br />
                                            Not a good idea for international transactions especially for paying as foreign university fee as the exchange rates gets fluctuated for every monetary instrument for different countries.
                        </p>
                                    <p className="description">
                                        • Bank transfer (exchange rate applied at the time of deposit)- <br />
                                                The bank you have your account with provides this facility for transferring your university fee. Every bank has its own foreign exchange department for catering to international transactions. The additional charges may apply for the same.
                        </p>
                                    <p className="description">
                                        •	Forex cards (pre-paid with the exchange rate fixed on the day of purchase)-<br />
                                                    These are the most secure way of transferring the amount internationally. Students, who wish to do multiple or single transaction without the hassle of exchange rates, should opt for this global convenience.
                        </p>
                                    <p className="description">
                                        •	International credit or debit cards-<br />
                                                        Just like your regular debit/credit is associated with your account, your bank also provides a card that is capable of handling your monetary transactions on the international spectrum. This is an expensive option as it includes the service charges every time you use it for withdrawal.
                        </p>
                                    <p className="description">
                                        •	Demand Draft- <br />
                                                            This facility will ease you from withdrawing the amount first and then sending to another account you just need to have sufficient balance in your account. For students willing to so international transactions especially as crucial as paying for the tuition fee, this method is impressive as it has no fixed turnaround time and could cost you your enrollment in the university.
                        </p>
                                </div>
                                <div className="detail-list">
                                    <h4 className="title-3">A general idea about Forex for students wishing to attend a foreign university-</h4>
                                    <p className="description">
                                        For Tuition &amp; Living Expenses: Approx $100,000 (annual depending upon the duration of your course) or as mentioned stated in your accepted application.
                        </p>
                                    <p className="description">
                                        For Basic Travel Quota (BTQ): $10,000 (approx)
                        </p>
                                </div>
                                <div className="detail-list">
                                    <h4 className="title-3">Why are prepaid forex cards so popular?</h4>
                                    <p className="description">
                                        Prepaid Forex cards look exactly like your debit/credit card loaded with the currency of your choice or multicurrency. These are considered a better option than carrying or exchanging cash which is subject to market rates. These cards function just like your regular chip based credit/debit cards secured with a pin, can be used for international transaction to universities and individuals. They also come with the facility of blocking or replacement on request. You can put in the entire amount in a single, easy to operate card. The benefit of taking a prepaid card is that most of them cover free travel insurance. Foreign Exchange companies like dfs Forex, with their global presence provide you the facility of reloading your currency from anywhere.
                        </p>
                                    <p className="description">
                                        International students are important for every country because of the revenue generation. Many universities generally offer scholarships to its excellent and deserved candidates. Some employers, government bodies provide sponsorship as well to their citizens and in that case making the fund transfer via forex services, makes it easier for both the paying and receiving parties. When applying for a forex card specifically tailored for international students, then keep in mind that these services thrive on your satisfaction.
                        </p>
                                </div>

                            </div> */}
                                <div className="text-container text-center pb-30">
                                    <Row className="pt-15">
                                        {/* <Col xs="5" className="text-right">
                                            <Link to="/corporate-enquiry">
                                                <button type="button" className="btn-round btn btn-info">
                                                    <i className="fas fa-bell pr-10"></i> Book Now
                                            </button>
                                            </Link>
                                        </Col> */}
                                        <Col xs="12" className="text-right">
                                            Blog Date: {DateFormat(data.blogDate, 'DD-MMM-YYYY')}<br />
                                            Category: {data.category}
                                        </Col>
                                    </Row>
                                </div>
                                <hr></hr>
                                {/* <div>
                                    <Row>
                                        <Col md="12">
                                            <h4 style={{ paddingLeft: "20px" }}>Comments</h4>
                                            {comments.map((item, index) => (

                                                <div key={index} className="col-lg-11 p-0">
                                                    <div className="comment-box">
                                                        <div className="comment-box-header">
                                                            {//<span ><img src="https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg" alt="" className="comment-image"/></span>
                                                            }
                                                            <span className="comment-name">{item.name}</span>
                                                            <div align="right" className="comment-date">{item.date}</div>

                                                        </div>
                                                        <div className="comment-body">
                                                            <span>{item.message}</span>
                                                        </div>
                                                        {//<div className="comment-box-footer">
                                                            //   <link>Reply</link>
                                                            //</div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" className="ml-auto mr-auto">
                                            <div className="card">
                                                <div className="card-header text-left title-2">
                                                    Leave a Comment
                                                    </div>
                                                <Form onSubmit={FnSubmitComment}>
                                                    <div className="card-body">
                                                        <Row>
                                                            {/* <Col md="7">
                                                                <FormGroup>
                                                                    <Label className="txt-left">Your Name</Label>
                                                                    <Input type="text" className="form-control" placeholder="Your Name"
                                                                        maxLength={50}
                                                                        value={formData.name || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, name: e.currentTarget.value || '' })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="7">
                                                                <FormGroup>
                                                                    <Label className="txt-left">Your Email</Label>
                                                                    <Input type="text" className="form-control" placeholder="Your Email"
                                                                        maxLength={50}
                                                                        value={formData.email || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, email: e.currentTarget.value || '' })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col> */}
                                {/* <Col md="12">
                                                                <FormGroup>
                                                                    <Label className="txt-left">Your Message</Label>
                                                                    <TextareaAutosize
                                                                        minRows={3}
                                                                        maxRows={10}
                                                                        maxLength={250}
                                                                        value={formData.message || ''}
                                                                        className="form-control" placeholder="Message"
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, message: e.currentTarget.value || '' })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <FormGroup className="comment-button">
                                                            {getData('session') ?
                                                                <button type="submit" className="btn-round btn btn-info">
                                                                    Submit Comment
                                                            </button>
                                                                :
                                                                <button type="submit" className="btn-round btn btn-info"
                                                                    onClick={
                                                                        e => {
                                                                            e.preventDefault();
                                                                            globalContext.headerDispatch({ type: 'HEADERLOGIN' })
                                                                        }
                                                                    }>
                                                                    Login to comments
                                                              </button>

                                                            }
                                                        </FormGroup>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                            </div>
                        </Row>
                    </Container>
                }
            </div>
        </>
    );
}
