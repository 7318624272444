
import logo from "assets/img/logo.png";
import axios from 'axios';

// export const BASE_URL = "https://projectw.bizpoint-intl.com/";
// export const API_BASE_URL = "https://whisshapi.bizpoint-intl.com/";

export const BASE_URL = "https://www.whissh.com.sg/";
export const API_BASE_URL = "https://whisshapi.whissh.com.sg/";

// export const BASE_URL = "https://projectwtest.whissh.com.sg/";
// export const API_BASE_URL = "https://whisshapitest.whissh.com.sg/";

// export const BASE_URL = "http://localhost:3000/";
// export const API_BASE_URL = "https://localhost:44325/";

export const AVTAR = BASE_URL + logo;

export const api = axios.create({
    baseURL: API_BASE_URL
});

// export const STRIPE_PUBLIC_KEY = "pk_test_BBZY2YDNr7H9PaBAINrkYwJa00fv8OYfci"
export const STRIPE_PUBLIC_KEY = "pk_live_51GyDuNIcwt3zuCpKqHZiYB0yeoDGbmLzQ7kqYJQ44gnlLNV76PrPwTUl8sYYHbPpaP4X47Vwhfoe5JZyPPp7YxqP00LhrLkWyG"
export const STRIPE_PUBLIC_KEY_3Y = "pk_live_51LL0gzDgcB5SnjGA5hqIAJo35x8PrNgCTEy8LAzXkpUmnFYCz0LP8leCgJ1FDYZUlGsDsL8LSxscV4RjTuktDOUM00w3tMll2K"
// export const BUILD_VERSION = "4"
// export const VERSION_URL = BASE_URL + "meta.json";

export const PUSH_PUBLIC_KEY = "BE5b6Js8cDEfd7UpG4X5QBRWLk4IovlexDYN3x3neehgSSW01-ngf2gAoobvOaCm_c-FMBeXvLLoirkdHiq21-I"
export const FACEBOOK_APP_ID = "1937892346345474";
export const GOOGLE_APP_ID = "244338783069-4dp98odlb636ntspagutmk0gukv5853q.apps.googleusercontent.com";

export const GOOGLE_TRANSLATION = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
export const GOOGLE_RECAPTCHA_SITE_KEY = "6LfYwL4ZAAAAAJVtD5v3o2L21KmaYIpVxrk4PUzV";

export const LANGUAGE = {
    default: 'en',
    primary: 'en',
    secondary: 'uk'
} 

export const SOCIALLINKS = {
    facebook: 'https://www.facebook.com/whissh.sg',
    google: 'https://www.google.com/',
    instagram: 'https://www.instagram.com/whisshsingapore/'
}

export const APPLINKS = {
    android: '#',
    apple: '#',
}

export const ENABLELOGIN = {
    facebook: false,
    google: false,
}

export const DEFAULTMETA = {
    title: 'Singapore\'s Premier Integrated Home Service Provider.',
    description: 'Whissh is your trusted home service and cleaning service provider.',
    keywords: 'Cleaning services, cleaning',
}