import React, { useContext, useReducer, useEffect } from 'react';
import { Button } from 'reactstrap';
import FacebookLogin from "react-facebook-login";
import { GlobalContext } from '../../App.js';
import { LoginInitialState, LoginReducer } from '../../reducer/LoginReducer';
import { storeData } from '../../utils/localStorage';
import { GetAPI, PostAPI } from '../../API/APICalling';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { GOOGLE_APP_ID, FACEBOOK_APP_ID, ENABLELOGIN } from '../../constant';
import { isiOS } from '../../utils/common';


export default React.memo(function SocialLogin(props) {
    // console.log(props.data.userSubscription);
    const globalContext = useContext(GlobalContext);
    const [loginState, loginDispatch] = useReducer(LoginReducer, LoginInitialState)
    // const { isLoading, username, password } = loginState;
    const history = useHistory()
    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const FnGetCartBadgeCount = async (arnum, email) => {
        var result = await GetAPI(`booking/cart/summary/${arnum}?email=${email}`);
        if (result) {
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                globalContext.commonDispatch({ type: 'SET_CART', payload: result.data });
                console.log(result.data)
            }
        }
    }

    const FnSocialLogin = async socialdata => {
        if (socialdata) {
            //console.log(socialdata);
            var result = await PostAPI(`user/sociallogin`, socialdata);
            if (result) {
                if (result.error) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    //props.data.onClickSendSubscriptionToPushServer(socialdata.deviceToken);

                    loginDispatch({ type: 'SUCCESS', payload: result.data });
                    globalContext.authDispatch({ type: 'SET', payload: result.data });
                    globalContext.notifications('success', 'Login successfully.');
                    // globalContext.headerState.loginFromHeader ?
                    //     globalContext.headerDispatch({ type: 'CLOSE' })
                    //     :
                    //     globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: globalContext.headerState.matcatnum })
                    storeData('session', result.data)
                    FnGetCartBadgeCount(result.data.arnum, result.data.email);
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });

                    if (globalContext.headerState.loginFromHeader) {
                        globalContext.headerDispatch({ type: 'CLOSE' });
                    }
                    else {
                        if (globalContext.headerState.skipqn) {
                            history.push({
                                pathname: '/package-listing',
                                state: { 'matcatnum': globalContext.headerState.matcatnum, 'displayaddon': globalContext.headerState.displayaddon }
                            })
                        }
                        else {
                            globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: globalContext.headerState.matcatnum })
                        }
                        // globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: globalContext.headerState.matcatnum });
                    }
                }
            }
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }

    const responseFacebook = async response => {
        var req = {};
        if (!isiOS()) {
            var abc = await props.data.getUserSubscription();
            var data = JSON.parse(JSON.stringify(abc, null, " "));
            if (data) {
                req = {
                    Endpoint: data.endpoint, P256DH: data.keys.p256dh, Auth: data.keys.auth
                }
            }
        }

        if (response.id) {
            const socialdata = {
                adapter: "facebook",
                email: response.email ? response.email : '',
                firstname: response.first_name ? response.first_name : '',
                lastname: response.last_name ? response.last_name : '',
                uid: response.id,
                deviceToken: JSON.stringify(req),
                deviceType: 'web'
            }
            FnSocialLogin(socialdata);
        }
        else if (response.status === 'unknown' || response.status === 'not_authorized') {
            // console.log('Please log into into Facebook.');
            globalContext.notifications('error', 'Please login into Facebook.');
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }

    const SocialLoginClicked = () => {
        // console.log(response)
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
    }

    const responseGoogle = async response => {
        var req = {};
        if (!isiOS()) {
            var abc = await props.data.getUserSubscription();
            var data = JSON.parse(JSON.stringify(abc, null, " "));
            if (data) {
                req = {
                    Endpoint: data.endpoint, P256DH: data.keys.p256dh, Auth: data.keys.auth
                }
            }
        }

        if (response.profileObj) {
            const res = response.profileObj;
            const socialdata = {
                adapter: "google",
                email: res.email ? res.email : '',
                firstname: res.givenName ? res.givenName : '',
                lastname: res.familyName ? res.familyName : '',
                uid: res.googleId,
                deviceToken: JSON.stringify(req),
                deviceType: 'web'
            }
            FnSocialLogin(socialdata);
        }
        else if (response.error === 'idpiframe_initialization_failed' || response.error === 'popup_closed_by_user'
            || response.error === 'access_denied' || response.error === 'immediate_failed') {
            globalContext.notifications('error', 'Please login into Google.');
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }

    return (
        <>
            <div>

                {ENABLELOGIN.facebook &&

                    <FacebookLogin
                        appId={FACEBOOK_APP_ID}
                        autoLoad={false}
                        disableMobileRedirect={true}
                        isMobile={true}
                        textButton="LOG IN WITH FACEBOOK"
                        fields="id,name,first_name,last_name,email"
                        onClick={SocialLoginClicked}
                        callback={responseFacebook}
                        cssClass="btn-round btn btn-info btn-block btn-facebook"
                        icon="p-5 fab fa-facebook-square"
                    />
                }
                {ENABLELOGIN.google &&
                    <GoogleLogin
                        clientId={GOOGLE_APP_ID}
                        buttonText="LOG IN WITH GOOGLE"
                        autoLoad={false}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        onRequest={SocialLoginClicked}
                        className={"text-center btn-round btn btn-info btn-block btn-google"}
                        cookiePolicy={'single_host_origin'}
                        icon="p-5 fab fa-google-plus"
                        render={renderProps => (
                            <Button type="button" className="btn-round btn btn-info btn-block btn-google"
                                onClick={renderProps.onClick}>
                                <i className="p-5 fab fa-google-plus"></i>LOG IN WITH GOOGLE</Button>
                        )}
                    />
                }
            </div>
        </>
    );
});
