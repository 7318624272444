import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

//https://www.npmjs.com/package/react-multi-carousel

import Carousel from 'react-multi-carousel';
//import '../../assets/css/react-multi-carousel.css';
import 'react-multi-carousel/lib/styles.css';
import { Link, useHistory } from "react-router-dom";
import { ReadRate } from "../../utils/common";

export default React.memo(function OtherCustomerServicePackage(props) {

    // console.log(props)

    const history = useHistory();
    const onSubmit = async (jobnum, matname, matnum, price) => {
        // console.log(jobnum)
        // console.log(matname)
        // console.log(matnum)
        // console.log(price)
        if (history.location.state.displayaddon) {
            const jobnum1 = jobnum.split(';')[0];
            const jobnum2 = jobnum.split(';')[1];
            history.push({
                pathname: '/addon-listing',
                state: { 'jobnum': jobnum1, 'jobnum2': jobnum2, 'matname': matname, 'matnum': matnum, 'baseamt': price }
            })
        }
        else {
            history.push({
                pathname: '/service-booking',
                state: { 'jobnum': jobnum, 'matname': matname, 'matnum': matnum, 'baseamt': price }
            })
        }
    }

    // let countyCarousel = React.createRef();
    // React.useEffect(() => {
    //     countyCarousel.current.style.width = "100%";
    //     // const updateScroll = () => {
    //     //     if (window.innerWidth > 700) {
    //     //         countyCarousel.current.style.width = "600px";
    //     //     }
    //     //     else {
    //     //         countyCarousel.current.style.width = "100%";
    //     //     }
    //     // };
    //     // window.addEventListener("scroll", updateScroll);
    //     // return function cleanup() {
    //     //     window.removeEventListener("scroll", updateScroll);
    //     // };
    // });

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,//6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,//5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 60 // this is needed to tell the amount of px that should be visible.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 60 // this is needed to tell the amount of px that should be visible.
        },
    };



    // const [items] = React.useState([
    //     {
    //         title: 'General Ceaning',
    //         amount: '30',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '23',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '35',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '45',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '30',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '50',
    //         desc: 'One Time, 4 Hours'
    //     }, {
    //         title: 'General Ceaning',
    //         amount: '75',
    //         desc: 'One Time, 4 Hours'
    //     }
    // ]);
    // const CountryItems = items.map((item) => (
    //     <div key={item.toString()}>
    //         <div className="other-services-card text-center">
    //             <div className="card-header text-center title-3">
    //                 {item.title}
    //             </div>

    //             <p className="m-0 p-15">
    //                 From <br></br>
    //                 <span className="dollor">$</span>
    //                 <span className="price">{item.amount}</span><br></br>
    //                     Per Hour
    //             </p>
    //             <hr></hr>
    //             <p>
    //                 {item.desc}
    //             </p>
    //             <hr className="mb-0"></hr>
    //             <a href="/service-booking" className="btn-round btn btn-info">Book Now</a>
    //         </div>
    //     </div >
    // ));

    const CountryItems = props.para.data.map((item) => (
        <div key={item.toString()}>
            <div className="other-services-card text-center">
                <div className="card-header text-center title-3">
                    {item.matname}
                </div>
                <p className="m-0 p-15">
                    {ReadRate(item.rate).beforerate}<br></br>
                    <span className="dollor">$</span>
                    <span className="price">{ReadRate(item.rate).rate}</span><br></br>
                    {ReadRate(item.rate).afterrate}
                </p>
                <hr></hr>
                <p>
                    {item.matname}
                </p>
                <hr className="mb-0"></hr>
                <button
                    type="button"
                    className="btn-round btn btn-info"
                    onClick={e => {
                        e.preventDefault();
                        onSubmit(item.jobnum, item.matname, item.matnum, item.price);
                    }}
                >
                    Book Now
                </button>
            </div>
        </div >
    ));

    // const CustomRightArrow = ({ onClick, ...rest }) => {
    //     const {
    //         onMove,
    //         carouselState: { currentSlide, deviceType }
    //     } = rest;
    //     // onMove means if dragging or swiping in progress.
    //     return <button onClick={() => onClick()} />;
    // };


    return (
        <>
            <Row className="mb-30">
                <div className="card">
                    <div className="card-header text-left title-3">
                        {props.para.title}
                    </div>
                    <div className="card-body pt-0">
                        <Container>
                            <Row>
                                <div className="ml-auto mr-auto"
                                    style={{ width: "100%" }}
                                >
                                    <Carousel
                                        swipeable={true}
                                        draggable={true}
                                        showDots={true}
                                        renderDotsOutside={true}
                                        responsive={responsive}
                                        ssr={false} // means to render carousel on server-side.
                                        infinite={false}
                                        arrows={true}
                                        autoPlay={false}
                                        autoPlaySpeed={1000}
                                        keyBoardControl={true}
                                        customTransition="transform .3s ease-in-out"
                                        transitionDuration={500}
                                        containerClass="carousel-container"
                                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                                        // deviceType={"desktop"}
                                        dotListClass="custom-dot-list-style"
                                        // itemClass="carousel-item-padding-40-px"
                                        partialVisible={false}
                                    >
                                        {CountryItems}
                                    </Carousel>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    <div className="card-footer">
                    </div>
                </div>
            </Row>
        </>
    );
})
