import React, { useEffect, useState, useContext } from "react";
// reactstrap components
// import { Link } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
function PrivacyPolicy() {
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState('');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const FnGetCMSContent = async () => {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`cms/privacypolicy`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setData(result.data.content.body || '')
                }
            }
        }
        FnGetCMSContent();
    }, []);
    return (
        <>
            <div className="adjust-navbar">
                <Container>
                    <Row dangerouslySetInnerHTML={{ __html: data }}></Row>
                    {/*    
                         <Row>                     
                        <Col className="ml-auto mr-auto text-left" md="10">
                            <h2 className="title-2">Privacy Policy</h2>
                            <p className="title-3">Welcome to the Whissh Privacy Policy</p>
                            <p className="description">
                                www.whissh.com.sg is owned and operated by 1 Plus Pte. Ltd. ("Whissh" or "we" or “us”), a company registered in Singapore. The following Privacy Policy describes how we as a data controller collect, use, disclose, and otherwise process personally identifiable information that we collect about individuals ("Personal Data") in connection with the services available from Whissh through the website located at “www.whissh.com.sg” ("Site"). References to the Site are deemed to include derivatives thereof, including but not necessarily limited to mobile websites and applications. By visiting the Site, you are consenting to the collection, use, disclosure and processing of your Personal Data as described in this Privacy Policy.
                            </p>
                            <p className="description">
                                On occasion, we may revise this Privacy Policy to reflect changes in the law, our Personal Data collection and use practices, the features of our Site, or advances in technology. If we make revisions that change the way we collect or use your Personal Data, those changes will be posted in this Privacy Policy and the effective date will be noted at the beginning of this Privacy Policy. Therefore, you should review this Privacy Policy periodically so that you are up to date on our most current policies and practices. We will also prominently post such material changes prior to implementing the change. If you do not agree with any changes or modifications to the Privacy Policy, please do not continue using the Site. You will be deemed to have consented to any modification of the Privacy Policy when you use the Site after the effective date of the modification.
                            </p>
                            <p className="description">
                                Data protection is a matter of trust and your privacy is important to us. We shall therefore only use your name and other information, which relates to you in the manner set out in this Privacy Policy. We will only collect information where it is necessary for us to do so and we will only collect information if it is relevant to our dealings with you. We will only keep your information for as long as we are either required to by law or as is relevant for the purposes for which it was collected.
                            </p>
                            <p className="description">
                                You can visit the Site (as defined in the Terms of Use) and browse without having to provide personal details. During your visit to the Site you remain anonymous and at no time can we identify you unless you have an account on the Site and log on with your user name and password.
                            </p>
                            <p className="description">
                                Whissh is committed to complying with the Personal Data Protection Act 2012.
                            </p>
                            <p className="description">
                                If you have any comments, suggestions or complaints, you may contact us by e-mail at contact@whissh.com.sg
                            </p>
                            <p className="title-3">
                                Information We Collect
                            </p>
                            <p className="description">
                                We collect Personal Data about you that you provide to us when using the Site. Personal Data may include your name, address, phone number, credit card details, e-mail address. In addition to this, when using our Site, even if you do not make a booking with us, we may collect other certain information, such as your geographic data and account password, search preferences related to specific searches, which browser you’re using, and information about your computer’s operating system, application version, language settings and pages that have been shown to you. If you are using a mobile device, we might also collect data that identifies your mobile device, device-specific settings and characteristics and latitude/longitude details.
                            </p>
                            <p className="description">
                                We will only be able to collect your personal information if you voluntarily submit the information to us. If you choose not to submit your personal information to us or subsequently withdraw your consent to our use of your personal information, we may not be able to provide you with our Services. You may access and update your personal information submitted to us at any time as described below.
                            </p>
                            <p className="description">
                                If you provide personal information of any third party to us, we assume that you have obtained the required consent from the relevant third party to share and transfer his/her personal information to us.
                            </p>
                            <p className="description">
                                If you sign up for any Whissh’s landing pages, web applications using your social media account or link your Whissh account to your social media account or use certain other Whissh social media features, we may access information about you which you have voluntarily provided under your social media provider in accordance with the provider's policies and we will manage your personal data which we have collected in accordance with Whissh’s privacy policy.
                            </p>


                            <p className="title-3">
                                Protecting Your Personal Data
                            </p>
                            <p className="description">
                                To prevent unauthorized access, we maintain reasonable physical, electronic and organizational procedures to protect Personal Data against accidental or unlawful destruction or accidental loss, alteration, or unauthorized disclosure or access. To protect credit card transactions, www.whissh.com.sg currently uses Secure Socket Layer (SSL) technology to encrypt credit card information during transit.
                            </p>

                            <p className="title-3">
                                How We Use the Information We Collect– Credit Card on File
                            </p>
                            <p className="description">
                                i) General Use: We use Personal Data and other information collected through the Site to (a) register you with the Site and create your user account, (b) provide you with the requested services (mainly to process and confirm your booking with the accommodation provider or other supplier) after verifying your credentials, (c) provide you with information about our services on the Site or on other websites and to communicate with you to the extent necessary to provide such requested services, (d) build features that will make the services available on the Site easier to use, (e) for an improved user experience, internal training and general optimization purposes, (f) for the detection and prevention of fraud and other illegal or unwanted activities or (g) contact you in case there is any issue with your booking. This includes faster purchase requests, better customer support and timely notice of new services and special offers.
                            </p>
                            <p className="description">
                                ii) Marketing Activities & Reminders: We also use your information for marketing activities, as permitted by law. For example: When you make a booking with us, set up a user account, complete or enter details into a booking form, or provide us with your contact details we may use your contact information to send you news of products and services relevant and useful for home onwers. With your consent – if consent is required under local law – we may also send you other regular newsletters by email. You can opt out, or unsubscribe, from marketing communications at any time using the "Unsubscribe" link in each newsletter or communication, or you can administer your subscriptions through your account.Based on the information you share with us, individualized offers may be shown to you on the Site, in mobile apps or on third-party websites, including social media sites. These might be offers that you can book directly on the Site, or third-party offers or products we think you might find interesting.If you have not finalized your booking online, we may send you a reminder to continue with your booking. Please see the section below titled “Cookies, Page Tags and Data Use” on how we use this data for advertising purposes.Please note that the confirmation emails and text messages sent after signing up and when you have made a booking are not marketing messages. These messages are part of your booking process. Respectively, they contain information for you to confirm your booking and service crew identity, and tools to optionally rate the service crew after the session. You will continue to receive them, even if you have opted out of our marketing messages.
                            </p>
                            <p className="description">
                                iii) Credit Card on File: When logging in, you will have the ability to save credit card details to your personal account for faster future bookings and payments. Your card details are always secure using strong encryption and you can always log in to your personal account to delete, edit or add certain credit card details. For security reasons, the number cannot be edited (but the card details can be deleted). When viewing card details, only the last 4 digits of the number will be visible. Please note that we will store the last 4 digits of your credit card securely upon any booking made (this is required to manage any refund requests for that booking).
                            </p>

                            <p className="title-3">
                                Sharing Your Personal Data
                            </p>
                            <p className="description">
                                Whissh may share your Personal Data with suppliers such as accommodation properties and other third parties we appoint to perform services on our behalf, such as third party loyalty programs, data analysis, web hosting, , marketing (also on websites of such third parties), customer care, credit card and payment services and related services. All such third parties will be under an obligation to maintain the security and confidentiality of the Personal Data, and to process the Personal Data only in accordance with our instructions.
                            </p>
                            <p className="description">
                                Whissh may propose other services (typically “add-ons”), to offer certain benefits or for another purpose that may interest you. These services may be offered by third party providers or Whissh. As stated above, relevant data about this service may be shared with such third party providers to ensure optimal service.
                            </p>
                            <p className="description">
                                Whissh may, in compliance with applicable law, disclose Personal Data to protect ourselves against liability, to respond to subpoenas, judicial processes, legitimate requests, warrants or equivalent by law enforcement officials or authorities, to investigate fraud or other wrongdoing or as otherwise required or necessary in order to comply with applicable law, protect our legitimate interests or to the purchasers in connection with any sale, assignment, or other transfer of all or a part of our business or company. We may also, in compliance with applicable law, disclose Personal Data to enforce or apply the terms and conditions applicable to our services or to protect the rights, property or safety of Whissh, our users or others.
                            </p>
                            <p className="description">
                                In the situations described above, the recipient of the Personal Data may be located in a jurisdiction that may have different standards of data protection compared to the laws in your home jurisdiction.
                            </p>


                            <p className="title-3">
                                Our Policy Concerning Cookies and Page Tags
                            </p>
                            <p className="description">
                                By visiting our Site, you consent to the usage of cookies and tags as described below. Our Site uses session and persistent cookies as well as page tags to collect and store certain information about you. A cookie is a small piece of data that is sent to your computer from the Site and stored on your computer’s hard drive. A page tag is a piece of code which reads and writes cookie data. Cookies or page tags may be from Whissh and/or third party vendors we have chosen to work with. Session cookies disappear from your computer when you close the browser. Persistent cookies remain on your computer after the browser has been closed, but can be deleted at any time through your browser settings. The use of these cookies and page tags may vary, and are described in the appropriate sections below.
                            </p>
                            <p className="description">
                                Opting Out<br />
                                If you want to prevent your browser from accepting cookies, if you want to be informed whenever a cookie is stored on your computer or if you want to delete cookies from your computer, please make the necessary changes in your Internet browser settings, typically under the sections “Help” or “Internet Options”. If you disable or delete cookies in your Internet browser settings, you might not be able to access or use important functions or features of this Site, and you will be required to re-enter your log-in details. Please also note that opting out of a third party cookie (see below) does not mean that you will no longer receive or be subject to online advertising or marketing analysis. It means that the network from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns. Please also note that if you clear all cookies on your browser, or use a different browser or computer, you will need to complete the opt-out procedure again.
                            </p>


                            <p className="title-3">
                                Cookies, Page Tags and Data Use
                            </p>
                            <p className="description">
                                Whissh Cookies<br />
Whissh uses a session cookie to keep you logged in throughout your visit to the Site.

                            </p>
                            <p className="description">
                                Whissh uses persistent cookies to "personalize" the Site for each user by remembering information about the user's visit to and activities on the Site, e.g., the language you selected, your log-in details so that you do not need to re-enter them at your next visit to the Site.
                            </p>
                            <p className="description">
                                Whissh also uses persistent cookies or page tags to analyze your surfing behavior and your visit to the Site enabling us to improve the Site and our services and to tailor our advertising (via email, on our Site, and on other websites) according to the interests of our users. Such cookies and page tags gather information about the pages you visited on the Site and the searches you performed.
                            </p>

                            <p className="description">
                                Website analytics<br />
Third party companies may use persistent cookies to analyze your surfing behavior on and your visit to this Site as well as other web sites enabling us to improve the Site and our services. Website analytics platforms may be used to collect unidentifiable information for reports such as impression reporting, demographic reporting and interest reporting.

                            </p>

                            <p className="description">
                                Third Party Cookies, Page Tags and Hashed Data<br />
Our third party advertising companies may place cookies or page tags on some of the pages you visit on the Site. These cookies or page tags are designed to collect non-personally identifiable information in order to analyze your interests and searches when visiting our Site and to serve you advertisements specifically tailored to your interests and searches when you are visiting our Site or other websites or when using mobile applications. The non-personally identifiable information collected through cookies and page tags may be shared with other third parties for purpose of managing and targeting advertisements and for market research analysis. In addition, we may share one-way hashed (undecipherable) data with third party vendors using advertisement targeting products. Such third party vendors typically use hashed device identifiers to link such identifiers to their own users and to serve custom ads based on users' prior visits to the Site. As a consequence, third party vendors may show our and/or our partners’ads on other websites or mobile apps.

                            </p>
                            <p className="description">
                                Advertising<br />
Whissh may use a combination of the above cookies and page tags to tailor your experience with our Site, our mobile application and our ads to your preferences. We use display and search remarketing to tailor ad content for a better experience.

                            </p>
                            <p className="description">
                                Where other advertising may not require the use of cookies or page tags, third party data is used to determine when an Whissh ad will be shown.
                            </p>


                            <p className="description">
                                Whissh takes reasonable efforts to not collect, and to prevent the use of, personally identifiable information in third party advertising solutions, unless you have authorized this. To opt out of collection of information by third parties for advertising purposes, please click on one or more of the third party links in the below “Opt Out Links” section.
                            </p>

                            <p className="description">
                                Opt Out Links<br />
                                If you would like more information about the information collection practices of third-party advertising companies and your choices about not having this information used by these companies, or you would like to opt out of online preference marketing, you can use the following tools:
                            </p>
                            <p className="description">
                                1. https://tools.google.com/dlpage/gaoptout?hl=en;
                                2. https://help.yahoo.com/kb/SLN4525.html;
                                Advertising and content on other websites
                            </p>

                            <p className="description">
                                Advertising and content on other websites<br />
                                    Whissh may use 3rd party tools to distribute ads on a variety of websites. Whissh neither supports nor endorses the goals, causes or statements of the websites which display our advertisements.
                            </p>


                            <p className="title-3">
                                How You Can Access or Change Your Personally Identifiable Information
                            </p>
                            <p className="description">
                                If you wish to update any Personal Data you have previously provided, or to exercise any right of access, correction, rectification, deletion, or opposition to the processing of your Personal Data that you may have under applicable law, please contact us via email (contact@whissh.com.sg)
                            </p>
                            <p className="description">
                                To protect your privacy and security, we will verify your identity before granting access or making changes to your Personal Data. Any request to access your Personal Data will be answered within a reasonable timeframe. You may also object to the processing of your Personal Data for purposes of advertising and market and opinion research. We reserve the right to charge a reasonable fee for such access in accordance with applicable laws.
                            </p>
                            <p className="description">
                                If you wish to contact our customer service representatives about privacy issues via email, you may send an email to contact@whissh.com.sg. You may also contact us at +65 6221 8626.
                            </p>


                            <p className="title-3">
                                Mobile Devices
                            </p>
                            <p className="description">
                                We have free mobile apps for a variety of mobile devices and use versions of our regular website that have been optimized for mobile. These apps and mobile websites process the personal details you give us in much the same way as our website does – and they also allow you to use location services to find accommodations nearby. With your consent, we may send you push notifications with information about your booking, potential future stays or as part of any Whissh programs that you participate in.
                            </p>
                            <p className="title-3">
                                Privacy Policy Updates
                            </p>
                            <p className="description">
                                If we change this Privacy Policy, we will post those changes on this page and update the Privacy Policy modification date below. If we materially change this Privacy Policy in a way that affects how we use or disclose your Personal Information, we will notify you by prominently posting a notice of such changes before making them and by stating the effective date of the changes.
                            </p>
                            <hr></hr>
                        </Col>
                    </Row>
                    */}
                </Container>
            </div>
        </>
    );
}

export default PrivacyPolicy;
