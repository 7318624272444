import React, { useEffect, useContext, useReducer } from "react";

// reactstrap components
import { Link, useHistory } from "react-router-dom";

import { Container, Row, Col, Button, FormGroup, Input, Label, CardHeader, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { GlobalContext } from '../../App.js';
import { getData } from '../../utils/localStorage'

import { validDate, pastDate, validDate2, beetweenDate, DateFormat, } from '../../utils/common'

// react plugins that creates an input with a date picker
import Datetime from "react-datetime";
// core components
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import { MyBookingListInitialState, MyBookingListReducer } from "../../reducer/MyAccountReducer";
import { GetAPI } from "../../API/APICalling";
import queryString from 'query-string'

export default function MyProfile() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyBookingListReducer, MyBookingListInitialState);

    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    useEffect(() => {

        if (state.activeTab === 'active') {
            dispatch({ type: 'FIELD', field: 'datefrom', value: Datetime.moment() })
            dispatch({ type: 'FIELD', field: 'dateto', value: Datetime.moment().add(30, 'day') })
            FnGetActiveBooking(Datetime.moment(), Datetime.moment().add(30, 'day'));
        }
        else {
            dispatch({ type: 'FIELD', field: 'datefrom', value: Datetime.moment().subtract(30, 'day') })
            dispatch({ type: 'FIELD', field: 'dateto', value: Datetime.moment() })
            FnGetHistoryBooking(Datetime.moment().subtract(30, 'day'), Datetime.moment());
        }

    }, [state.activeTab]);

    const FnGetActiveBooking = async (datefrom, dateto) => {
        const session = getData('session');
        if (window.location.search=="?f=s" && !state.successShown){
            state.successShown = true;
            globalContext.notifications('success', 'You have successfully made a payment.  Please take note of the booking schedule');
        }
        
        //console.log("FnGetActiveBooking",this.props)
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`mybooking/active/${session.arnum}?datefrom=${DateFormat(datefrom, 'YYYY-MM-DD')}&dateto=${DateFormat(dateto, 'YYYY-MM-DD')}`);
            // var result = await APIActiveBooking(session.arnum);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // setActiveData(result.data);
                    dispatch({ type: 'ACTIVE_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnGetHistoryBooking = async (datefrom, dateto) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`mybooking/history/${session.arnum}?datefrom=${DateFormat(datefrom, 'YYYY-MM-DD')}&dateto=${DateFormat(dateto, 'YYYY-MM-DD')}`);
            // var result = await APIHistoryBooking(session.arnum);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // setHistoryData(result.data);
                    dispatch({ type: 'HISTORY_SUCCESS', payload: result.data });
                }
            }
        }
    };

    return (
        <>

            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link
                                    to="/">
                                    Home
                              </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link
                                    to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                              </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                My Booking
                            </li>
                        </ol>
                    </Container>
                </div>
                <div className="myaccount-header">
                    <MyAccountHeader />
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row className="pt-30">
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <h2 className="big-heading">My Bookings</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col md="12" className="ml-auto mr-auto">
                                            <div className="card" id="myBooking">
                                                <CardHeader
                                                    className="pb-0"
                                                >
                                                    <Nav
                                                        style={{ display: "flex" }}
                                                        className="nav-tabs-neutral text-left pb-0 pt-0"
                                                        data-background-color="white"
                                                        role="tablist"
                                                        tabs
                                                    >
                                                        <NavItem>
                                                            <NavLink
                                                                className={state.activeTab === "active" ? "mybooking-tab active" : "mybooking-tab"}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    dispatch({ type: 'SET_ACTIVE', payload: 'active' });
                                                                }}
                                                            >
                                                                Active
                                                        </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={state.activeTab === "history" ? "mybooking-tab active" : "mybooking-tab"}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    dispatch({ type: 'SET_ACTIVE', payload: 'history' });
                                                                }}
                                                            >
                                                                History
                                                        </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <div>
                                                        <Row>
                                                            <Col md="3" xs="12">
                                                                <Label className="badge badge-neutral">Date From:</Label>
                                                                <FormGroup>
                                                                    <Datetime
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        inputProps={{ placeholder: "YYYY-MM-DD" }}
                                                                        closeOnSelect={true}
                                                                        value={state.datefrom}
                                                                        //value={state.reqdate}
                                                                        isValidDate={e => state.activeTab === 'active' ? validDate(e) : pastDate(e)}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'DATEFROM_FIELD',
                                                                                field: 'datefrom',
                                                                                value: e._d,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>


                                                            <Col md="3" xs="12">
                                                                <Label className="badge badge-neutral">Date To:</Label>
                                                                <FormGroup>
                                                                    <Datetime
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        inputProps={{ placeholder: "YYYY-MM-DD" }}
                                                                        closeOnSelect={true}
                                                                        value={state.dateto}
                                                                        //value={state.reqdate}
                                                                        // isValidDate={validDate}
                                                                        isValidDate={e => state.activeTab === 'active' ? (validDate2(e, (Datetime.moment(state.datefrom)))) : (beetweenDate(e, state.datefrom))}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'dateto',
                                                                                value: e._d,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="3" xs="12">
                                                                <FormGroup className="filter">
                                                                    <Button className="btn btn-round btn-info"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            state.activeTab === 'active' ? FnGetActiveBooking(state.datefrom, state.dateto) : FnGetHistoryBooking(state.datefrom, state.dateto);
                                                                        }}

                                                                    >Filter</Button>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="3" xs="12">
                                                                <FormGroup className="search">
                                                                    <Input type="search" placeholder="Search Ticket"
                                                                        value={state.searchString}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FILTER_RESULT',
                                                                                field: 'searchString',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <TabContent activeTab={"pills" + state.activeTab}>
                                                        <TabPane tabId="pillsactive">
                                                            <div className="table-responsive table-list-view" id="myBookingTable">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-left">Ticket No.</th>
                                                                            <th className="text-left">Session No.</th>
                                                                            <th className="text-left">Service Date</th>
                                                                            <th className="text-left">Assigned Crew</th>
                                                                            <th className="text-left">Service Type</th>
                                                                            <th className="text-left">Status</th>
                                                                            <th className="text-center">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {state.activeFilterResult &&
                                                                            <React.Fragment>
                                                                                {
                                                                                    state.activeFilterResult.length > 0 &&
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            state.activeFilterResult.map((item, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="text-center">{item.ticket}</td>
                                                                                                    <td className="text-left">{item.sessionNo}</td>
                                                                                                    <td className="text-left">{DateFormat(item.serviceDate, 'ddd, DD-MMM-YYYY h:mm a')}</td>
                                                                                                    <td className="text-left contactName">{item.assignedCrew}</td>
                                                                                                    <td className="text-left">{item.serviceType}</td>
                                                                                                    <td className={item.status === "Open" ? "pending text-left" : "confirmed text-left"}>{item.status}</td>
                                                                                                    <td className="text-center">
                                                                                                        <Link to={`/my-booking-details/active/${item.refnum}`}>
                                                                                                            <img
                                                                                                                alt="Whissh Image"
                                                                                                                className="rounded-circle"
                                                                                                                src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                                                style={{ maxHeight: "25px", margin: "5px" }}
                                                                                                            ></img>
                                                                                                        </Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        }


                                                                        {/* <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                            </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left confirmed">Confirmed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left pending">Pending</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left pending">pending</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left confirmed">Confirmed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left confirmed">Confirmed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left confirmed">Confirmed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                            </td>
                                                                        </tr> */}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tabId="pillshistory">
                                                            <div className="table-responsive table-list-view" id="myBookingHistoryTable">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-left">Ticket No.</th>
                                                                            <th className="text-left">Session No.</th>
                                                                            <th className="text-left">Service Date</th>
                                                                            <th className="text-left">Assigned Crew</th>
                                                                            <th className="text-left">Service Type</th>
                                                                            <th className="text-left">Status</th>
                                                                            <th className="text-center">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {state.historyFilterResult &&
                                                                            <React.Fragment>
                                                                                {
                                                                                    state.historyFilterResult.length !== 0 &&
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            state.historyFilterResult.map((item, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="text-center">{item.ticket}</td>
                                                                                                    <td className="text-left">{item.sessionNo}</td>
                                                                                                    <td className="text-left">{DateFormat(item.serviceDate, 'ddd, DD-MMM-YYYY h:mm a')}</td>
                                                                                                    <td className="text-left contactName">{item.assignedCrew}</td>
                                                                                                    <td className="text-left">{item.serviceType}</td>
                                                                                                    <td className={item.status === "Open" ? "pending text-left" : "confirmed text-left"}>{item.status}</td>
                                                                                                    <td className="text-center">
                                                                                                        <Link to={`/my-booking-details/history/${item.refnum}`}>
                                                                                                            <img
                                                                                                                alt="Whissh Image"
                                                                                                                className="rounded-circle"
                                                                                                                src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                                                style={{ maxHeight: "25px", margin: "5px" }}
                                                                                                            ></img>
                                                                                                        </Link>
                                                                                                        {/* <Button className="btn btn-round btn-info p-5"
                                                                                                        >REBOOK</Button> */}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                        {/* <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left completed">Completed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-history-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                                <Button className="btn btn-round btn-info p-5"
                                                                                >REBOOK</Button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left cancelled">Cancelled</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-history-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                                <Button className="btn btn-round btn-info p-5"
                                                                                >REBOOK</Button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left cancelled">Cancelled</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-history-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                                <Button className="btn btn-round btn-info p-5"
                                                                                >REBOOK</Button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                0123456789
                                                                    </td>
                                                                            <td className="text-left">1/8</td>
                                                                            <td className="text-left">01/02/2020</td>
                                                                            <td className="text-left contactName">Assigned Crew</td>
                                                                            <td className="text-left">General/Weekly Cleaning</td>
                                                                            <td className="text-left completed">Completed</td>
                                                                            <td className="text-center">
                                                                                <a href="/my-account/my-booking-history-details">
                                                                                    <img
                                                                                        alt="Whissh Image"
                                                                                        className="rounded-circle"
                                                                                        src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                        style={{ maxHeight: "25px", margin: "5px" }}
                                                                                    ></img>
                                                                                </a>
                                                                                <Button className="btn btn-round btn-info p-5"
                                                                                >REBOOK</Button>
                                                                            </td>
                                                                        </tr> */}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
