import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import { gtagConversionEvent } from "../utils/common";
import ReactPixel from 'react-facebook-pixel'

// core components
// import OtherCustomerServicePackage from "components/PackageList/OtherCustomerServicePackage.js"


function PaymentSuccessfullyDone() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [txnId, setTxnId] = useState('');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        gtagConversionEvent("AW-730252635/1x5wCO-mmNACENuKm9wC");
        ReactPixel.track('Purchase');

        if (history.location.state !== undefined) {
            // setIsSuccess(true);
            if (history.location.state.txnId !== '')
                setTxnId(history.location.state.txnId);
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }
    }, []);

    return (
        <>
            <div id="PaymentSuccessfull" className="adjust-navbar">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            {!isLoading &&
                                <Row className="pb-15">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <img
                                                alt="Whissh Image"
                                                src={require("../assets/img/icon-login.png")}
                                            ></img>
                                        </div>
                                        {txnId ? (
                                            <React.Fragment>
                                                <div className="text-center">
                                                    <h2 className="big-heading p-5">Payment Successful !</h2>
                                                    <b className="title-3 p-10">You have Successfully completed your booking.</b><br />
                                                    <p className="description p-10">Your Transaction Id: {txnId}</p>
                                                </div>
                                                <hr className="hr-large"></hr>
                                                <Button
                                                    className={"btn btn-round btn-info"}
                                                    to="/my-account/my-booking" tag={Link}
                                                >
                                                    VIEW BOOKING
                                                 </Button>
                                            </React.Fragment>
                                        ) :
                                            (
                                                <React.Fragment>
                                                    <div className="text-center">
                                                        <h2 className="big-heading p-5">Payment Failed !</h2>
                                                        <b className="title-3 p-10">Please try again..</b>
                                                        {/* <b className="title-3 p-10">Your Transaction Id: {txnId}</b> */}
                                                    </div>
                                                    <div className="text-center">
                                                        <Link
                                                            className="btn btn-round btn-default"
                                                            to={{
                                                                pathname: '/service-booking',
                                                                state: { 'redirect': 'FINALIZE' }
                                                            }}
                                                        >
                                                            My Cart
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    </Col>
                                </Row>
                            }
                            {/* <OtherCustomerServicePackage para={{ title: "Customers who booked your service also booked these" }} /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PaymentSuccessfullyDone;
