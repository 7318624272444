export const FnCreateProductList = (product, type) => {
    let productList = [];
    try {
        product.forEach(item => {
            let addonList = [];
            item.addonList && item.addonList.forEach(ite => {
                addonList.push({
                    choicetype: ite.choicetype || '',
                    groupdesc: ite.groupdesc || '',
                    groupnum: ite.groupnum || '',
                    maxchoice: parseInt(ite.maxchoice || 0),
                    minchoice: parseInt(ite.minchoice || 0),
                    optiondesc: ite.optiondesc || '',
                    optionnum: ite.optionnum || '',
                    price: parseFloat(ite.price || 0),
                    selected: ite.selected || false,
                    qty: parseInt(ite.qty || 0),
                    uom: ite.uom || '',
                    totalamt: parseFloat(ite.totalamt || 0),
                    qtyeditable: ite.qtyeditable || false,
                    imageurl: ite.imageurl || ''
                })
            });

            productList.push({
                _saveAddress: item._saveAddress === undefined ? false : item._saveAddress,
                addon: item.addon || '',
                baseamt: parseFloat(item.baseamt || 0),
                discamt: parseFloat(item.discamt || 0),
                disctype: item.disctype || '',
                duration: parseInt(item.duration || 0),
                endtime: item.endtime || '',
                imagefile: item.imagefile || '',
                jobnum: item.jobnum || '',
                matname: item.matname || '',
                matnum: item.matnum || '',
                optamt: parseFloat(item.optamt || 0),
                promocode: item.promocode || '',
                guid: item.guid || '',
                qty: type === 'remove' ? 0 : parseInt(item.qty || 1),
                refnum: item.refnum || '',
                remarks: item.remarks || '',
                sessionQty: parseInt(item.sessionQty || 0),
                shiftnum: item.shiftnum || '',
                shipaddr1: item.shipaddr1 || '',
                shipaddr2: item.shipaddr2 || '',
                shipaddr3: item.shipaddr3 || 'Singapore',
                shipbathroomQty: parseInt(item.shipbathroomQty || 0),
                shipbedroomQty: parseInt(item.shipbedroomQty || 0),
                shipemail: item.shipemail || '',
                shipfirstname: item.shipfirstname || '',
                shipfulladdr: item.shipfulladdr || '',
                shiphousetype: item.shiphousetype || '',
                shiplastname: item.shiplastname || '',
                shiplocationid: item.shiplocationid || '',
                shipphone: item.shipphone || '',
                shippostcode: item.shippostcode || '',
                shipptc: item.shipptc || '',
                shipptcname: item.shipptcname || '',
                shipsize: parseInt(item.shipsize || 0),
                shipunitno: item.shipunitno || '',
                starttime: item.starttime || '',
                startdate: item.startdate || new Date("1/1/0001 12:00:00"),
                totalamt: parseFloat(item.totalamt || 0),
                totalLocamt: parseFloat(item.totalLocamt || 0),
                rate: item.rate || '',
                frequency: item.frequency || '',
                totalWkdamt: parseFloat(item.totalWkdamt || 0),

                jobnum2: item.jobnum2 || '',
                shiftnum2: item.shiftnum2 || '',
                startdate2: item.startdate2 || new Date("1/1/0001 12:00:00"),
                starttime2: item.starttime2 || '',
                endtime2: item.endtime2 || '',
                isMultijob: item.isMultijob || false,
                hasAddon: item.hasAddon || false,
                addonList: addonList,
                shiptitle: item.shiptitle || ''
            });
        });
    }
    catch (e) {
        console.log(e);
    }
    return productList;
};