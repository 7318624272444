import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Input, ModalHeader, ModalBody, } from "reactstrap";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../App';
import { CardField, ErrorMessage, SubmitButton } from '../../components/CheckOutPayment/CheckoutProperty';
import { PostAPI } from '../../API/APICalling';
import { api } from "../../constant";
import { getData } from '../../utils/localStorage';
import { ValidateEmail } from '../../utils/common';

export default function TopUpModal(props) {
    const globalContext = useContext(GlobalContext);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [cardComplete, setCardComplete] = useState(false);
    const [txnId, setTxnid] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {

        const session = getData('session');
        if (session) {
            setPaymentData({ ...paymentData, email: session.email || '', phoneNumber: session.mobile || '' });
        }
        if (processing) {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        }
        else {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }, [processing])

    const FnValidate = () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else if (!paymentData.paidamt) {
            globalContext.notifications('error', 'Please select topup amount.');
            return false
        }
        else if (!paymentData.email) {
            globalContext.notifications('error', 'Email address is required.');
            return false
        }
        else if (!ValidateEmail(paymentData.email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }
        else if (!paymentData.phoneNumber) {
            globalContext.notifications('error', 'Mobile number is required.');
            return false
        }
        else if (!paymentData.fullname) {
            globalContext.notifications('error', 'Fullname is required.');
            return false
        }
        return true
    }

    const FnConfirmPayment = async (event) => {
        try {
            event.preventDefault();
            if (FnValidate()) {
                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }
                if (error) {
                    elements.getElement('card').focus();
                    return;
                }

                if (cardComplete)
                    setProcessing(true);
                else
                    return;

                const session = getData('session');
                if (!session) {
                    globalContext.notifications('error', 'Your session has been expired, Please login again.');
                    history.push("/");
                }
                else {
                    //First sending Payable details to the userver.
                    var result = await PostAPI(`mycredit/${session.arnum}`,
                        {
                            arnum: session.arnum,
                            payref: '',
                            qty: parseFloat(paymentData.paidamt || 0)
                        }
                    );

                    if (result) {
                        if (result.error) {
                            setProcessing(false);
                            setError({ message: result.error });
                        }
                        else if (result.data) {
                            const billingDetails = {
                                name: paymentData.fullname || '',
                                email: paymentData.email || '',
                                phone: paymentData.phoneNumber || ''
                            };
                            const payload = await stripe.createPaymentMethod({
                                type: 'card',
                                card: elements.getElement(CardElement),
                                billing_details: billingDetails
                            });
                            if (payload.error) {
                                setProcessing(false);
                                setError(payload.error);
                            } else {
                                handlePaymentMethodResult(payload, session.arnum, result.data);
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            setProcessing(false);
        }
    };

    const handlePaymentMethodResult = async (result, arnum, refnum) => {
        try {
            if (result.error) {
                // An error happened when collecting card details, show it in the payment form
                setProcessing(false);
                setError({ message: result.error });
            } else {
                // Otherwise send paymentMethod.id to your server (see Step 3)
                api.post('checkout/initiatetopup', {
                    PaymentMethodId: result.paymentMethod.id,
                    paidamt: parseFloat(paymentData.paidamt || 0),
                    arnum: arnum,
                    refnum: refnum
                }).then(function (result) {
                    // return result.data;
                    handleServerResponse(result.data, refnum);
                }); //.then(handleServerResponse(refnum));
            }
        }
        catch (e) {
            setProcessing(false);
        }
    }

    const handleServerResponse = async (responseJson, refnum) => {
        try {
            if (responseJson.error) {
                // Show error from server on payment form
                setProcessing(false);
                setError({ message: responseJson.error });
            } else if (responseJson.requiresAction) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    responseJson.clientSecret
                ).then(function (result) {
                    if (result.error) {
                        // Show `result.error.message` in payment form
                        setProcessing(false);
                        setError(result.error);
                    } else {
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server
                        api.post('checkout/confirmtopup', {
                            PaymentIntentId: result.paymentIntent.id,
                            refnum: refnum
                        }).then(function (confirmResult) {
                            handleServerResponse(confirmResult.data, refnum);
                        }); //.then(handleServerResponse);
                    }
                });
            } else {
                // Show success message
                setProcessing(false);
                setIsSuccess(true);
                setTxnid(responseJson.transactionId);
                //FnGetCreditSummary
                props.Function.CallBackFunction1();
                //FnGetCreditList
                props.Function.CallBackFunction2();
            }
        }
        catch (e) {
            setProcessing(false);
        }
    }

    return (
        <>
            <div className="topup-credits-modal">
                <ModalHeader toggle={() => props.Function.setModal(false)}>
                </ModalHeader>
                <ModalBody className="p-0">
                    <div className="card">
                        <form onSubmit={FnConfirmPayment}>
                            <div className="card-header text-left title-2">
                                Top-up Credits
                                </div>
                            {txnId ?
                                <React.Fragment>
                                    <div className="pt-50 pb-50">
                                        <div className="text-center">
                                            <img
                                                alt="Whissh Image"
                                                style={{ width: "60px" }}
                                                src={require("../../assets/img/icon-login.png")}
                                            ></img>
                                        </div>
                                        {isSuccess ? (
                                            <React.Fragment>
                                                <div className="text-center">
                                                    <h2 className="title-2 p-5">Payment Successfull !</h2>
                                                    <b className="title-3 p-10">You have Successfully completed your Top Up credit.</b><br />
                                                    <p className="description p-10">Your Transaction Id: {txnId}</p>
                                                </div>
                                            </React.Fragment>
                                        ) :
                                            (
                                                <React.Fragment>
                                                    <div className="text-center">
                                                        <h2 className="big-heading p-5">Payment Failed !</h2>
                                                        <b className="title-3 p-10">Please try again..</b>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="card-body">
                                        <span className="title-3">Choose amount of credits</span>
                                        {/* <p className="description">$X.XX = XXX Credits</p> */}
                                        <Row>
                                            <Col lg="3" md="6" sm="6" xs="6" className="pl-5 pr-5">
                                                <div className={paymentData.paidamt === 100 ? "topup-box active" : "topup-box"}
                                                    onClick={e => {
                                                        setPaymentData({ ...paymentData, paidamt: 100 })
                                                    }}
                                                >$100</div>
                                            </Col>
                                            <Col lg="3" md="6" sm="6" xs="6" className="pl-5 pr-5">
                                                <div className={paymentData.paidamt === 500 ? "topup-box active" : "topup-box"}
                                                    onClick={e => {
                                                        setPaymentData({ ...paymentData, paidamt: 500 })
                                                    }}
                                                >$500</div>
                                            </Col>
                                            <Col lg="3" md="6" sm="6" xs="6" className="pl-5 pr-5">
                                                <div className={paymentData.paidamt === 1000 ? "topup-box active" : "topup-box"}
                                                    onClick={e => {
                                                        setPaymentData({ ...paymentData, paidamt: 1000 })
                                                    }}
                                                >$1000</div>
                                            </Col>
                                            <Col lg="3" md="6" sm="6" xs="6" className="pl-5 pr-5">
                                                <div className={paymentData.paidamt === 2000 ? "topup-box active" : "topup-box"}
                                                    onClick={e => {
                                                        setPaymentData({ ...paymentData, paidamt: 2000 })
                                                    }}
                                                >$2000</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" md="12" sm="12" xs="12">
                                                <div className="text-center">OR</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" md="12" sm="12" xs="12">
                                                <FormGroup>
                                                    <Input type="number" className="form-control" placeholder="Flexible Amount"
                                                        value={paymentData.paidamt || 0}
                                                        onChange={e => {
                                                            setPaymentData((parseInt(e.currentTarget.value || 0) >= 0 && parseInt(e.currentTarget.value || 0) <= 1000000) ?
                                                                {
                                                                    ...paymentData,
                                                                    paidamt: parseInt(e.currentTarget.value || 0)
                                                                }
                                                                :
                                                                paymentData
                                                            )
                                                            // setPaymentData({ ...paymentData, paidamt: e.currentTarget.value })
                                                        }
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Input type="text" className="form-control" placeholder="E-mail*"
                                                        maxLength={"50"}
                                                        value={paymentData.email || ''}
                                                        onChange={e =>
                                                            setPaymentData({ ...paymentData, email: e.currentTarget.value })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Input type="number" className="form-control" placeholder="Phone number*"
                                                        value={paymentData.phoneNumber || ''}
                                                        onChange={e =>
                                                            setPaymentData({ ...paymentData, phoneNumber: e.currentTarget.value })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Input type="text" className="form-control" placeholder="Full Name On Card"
                                                        value={paymentData.fullname || ''}
                                                        onChange={e => {
                                                            setPaymentData({ ...paymentData, fullname: e.currentTarget.value })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" id="StripeCheckout">
                                                <CardField
                                                    options={{ hidePostalCode: true }}
                                                    onChange={(e) => {
                                                        setCardComplete(e.complete);
                                                        setError(e.error);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="card-footer text-center">
                                        <Button
                                            className={"btn btn-round btn-default"}
                                            onClick={e => {
                                                e.preventDefault();
                                                props.Function.setModal(false)
                                            }} >
                                            CANCEL
                               </Button>
                                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                                        <SubmitButton processing={processing} error={error} disabled={!(stripe && cardComplete)}>
                                            CONFIRM PAYMENT
                            </SubmitButton>
                                    </div>
                                </React.Fragment>
                            }
                        </form>
                    </div>
                </ModalBody>
            </div>
        </>
    );
}
