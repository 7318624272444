import React, { useContext, useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../App';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { CardField } from '../../components/CheckOutPayment/CheckoutProperty';
import { GetAPI, PostAPI } from '../../API/APICalling';
import { api } from "../../constant";
import { FnCountryCode } from '../../utils/CountryCode';


const PaynowForm = forwardRef((props, ref) => {

    const globalContext = useContext(GlobalContext);
    const sbContext = useContext(ServiceBookingContext);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [message, setMessage] = useState(null);
    // const [error, setError] = useState(null);
    //const [cardComplete, setCardComplete] = useState(false);
    // const [processing, setProcessing] = useState(false);
    //const [isSuccess, setIsSuccess] = useState(false);
    //const [paymentMethod, setPaymentMethod] = useState(null);
    // const [billingDetails, setBillingDetails] = useState({
    //     email: '',
    //     phone: '',
    //     name: '',
    // });

    useEffect(() => {
        if (sbContext.CheckoutState.processing) {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        }
        else {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }, [sbContext.CheckoutState.processing])


    const handleSubmit = async (coy) => {
        try {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: `${window.location.protocol}//${window.location.host}/payment-result/${coy}` //"http://localhost:3001",
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    }

    useImperativeHandle(ref, () => {
        return {
            handleSubmit: handleSubmit
        };
    });

    const paymentElementOptions = {
        layout: "tabs"
    }
    return (
        <>
            <PaymentElement options={paymentElementOptions}></PaymentElement>
            {message && <div id="payment-message">{message}</div>}
        </>
    )
});

export default PaynowForm;