import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Button, FormGroup, Label, Input, Form } from "reactstrap";
import TextareaAutosize from 'react-textarea-autosize';
import ReCAPTCHA from "react-recaptcha";
import { validDate } from "../../utils/common";
import DateTime from "react-datetime";
import { GlobalContext } from '../../App';
import { PostAPI } from "../../API/APICalling";
import { ValidateEmail } from "../../utils/common";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constant";
import { useHistory } from "react-router-dom";

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 2)

export default function EnquiryForm() {
    const globalContext = useContext(GlobalContext);
    const [formData, setFormData] = useState({});
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setFormData({ ...formData, preferreddate: tomorrow })
    }, []);

    // specifying your onload callback function
    const reCaptchLoaded = async => {
        console.log('Done!!!!');
    };

    // specifying verify callback function
    const verifyCallback = async (response) => {
        if (response)
            setCaptchaVerified(true);
    };

    const reCaptchExpired = async (response) => {
        setCaptchaVerified(false);
    };

    const FnSubmitEnquiry = async (e) => {
        e.preventDefault();
        // console.log(formData);

        if (!formData.email) {
            globalContext.notifications('error', 'Email address is required.');
            return false;
        }
        if (!ValidateEmail(formData.email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }
        if (!formData.firstname) {
            globalContext.notifications('error', 'First Name is required.');
            return false;
        }
        if (!formData.mobile) {
            globalContext.notifications('error', 'Phone Number is required.');
            return false;
        }
        if (!formData.postcode) {
            globalContext.notifications('error', 'Postal Code is required.');
            return false;
        }
        if (!formData.iagree) {
            globalContext.notifications('error', 'You must agree to the privacy policy.');
            return false;
        }
        if (!captchaVerified) {
            globalContext.notifications('error', 'Please verify captcha.');
            return false;
        }
        else {
            if (formData) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`home/corpenquiry`, formData);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        setFormData({});
                        history.push(
                            {
                                pathname: "/thankyou"
                            });
                        globalContext.notifications('success', 'Your request has been sumitted successfully.');
                    }
                }
            }
        }
    }


    return (
        <>
            <div className="adjust-navbar">
                <Container>
                    <Form onSubmit={FnSubmitEnquiry}>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="12">
                                <Row className="pb-10">
                                    <Col xl="8" lg="10" md="12" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <h1 className="big-heading">Enquiry Form</h1>
                                        </div>
                                        <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                                <Row className="mb-15">
                                    <Col xl="8" lg="10" md="12" className="ml-auto mr-auto ">
                                        <div className="card">
                                            <div className="card-header text-left title-2">
                                                Please fill all the details.
                                        </div>
                                            <div className="card-body">
                                                <Row>
                                                    <Col md="12" className="text-left">
                                                        <p className="description">This Information will be used to contact you about your service.</p>
                                                    </Col>
                                                    {/* <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">UEN ID:</Label>
                                                            <Input type="text" className="form-control" placeholder="UEN ID"
                                                                maxLength={10}
                                                                value={formData.uenid || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, uenid: e.currentTarget.value || '' })
                                                                }}
                                                            />

                                                        </FormGroup>
                                                    </Col> 
                                                    <Col md="6">

                                                    </Col> */}
                                                    <Col md="12">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Type Of Service:</Label>
                                                            <select className="form-control"
                                                                value={formData.servicetype || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, servicetype: e.currentTarget.value || '' })
                                                                }}>
                                                                <option value="">Type Of Service</option>
                                                                {
                                                                    globalContext.commonState.megamenuitems.map((item, index) => (
                                                                        item.subservices.map((item2, index2) => (
                                                                            <option key={index2} value={item2.subsvcnum}>{item2.subsvcname}</option>
                                                                        ))
                                                                    ))
                                                                }

                                                                {/* <option value="General Cleaning (4 hours)">General Cleaning (4 hours)</option>
                                                                 <option value="Weekly Cleaning"> Weekly Cleaning</option>
                                                                 <option value="Post Renovation Cleaning">Post Renovation Cleaning</option>
                                                                 <option value=" Post Renovation Cleaning(Home Improvement Program)"> Post Renovation Cleaning(Home Improvement Program)</option>
                                                                 <option value="Move In Cleaning">Move In Cleaning</option>
                                                                 <option value=" Move Out Cleaning"> Move Out Cleaning</option>
                                                                 <option value=" Spring Cleaning"> Spring Cleaning</option>
                                                                 <option value="Office Cleaning">Office Cleaning</option>
                                                                 <option value="Disinfection Service">Disinfection Service</option>
                                                                 <option value="Aircon Servicing">Aircon Servicing</option>
                                                                 <option value="Laundry Service">Laundry Service</option>
                                                                 <option value="Upholstery Cleaning">Upholstery Cleaning</option>
                                                                 <option value="Carpet Cleaning">Carpet Cleaning</option> */}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Salutation:</Label>
                                                            <select className="form-control"
                                                                value={formData.title || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, title: e.currentTarget.value || '' })
                                                                }}>
                                                                <option value="">Salutation</option>
                                                                <option value="Ms">Ms</option>
                                                                <option value=" Mr"> Mr</option>
                                                                <option value=" Dr"> Dr</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Name*:</Label>
                                                            <Input type="text" className="form-control" placeholder="Name*"
                                                                maxLength={50}
                                                                value={formData.firstname || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, firstname: e.currentTarget.value || '' })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Last Name:</Label>
                                                            <Input type="text" className="form-control" placeholder="Last Name"
                                                                maxLength={20}
                                                                value={formData.lastname || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, lastname: e.currentTarget.value || '' })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col md="12">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Phone Number*:</Label>
                                                            <Input type="text" className="form-control" placeholder="Phone Number*"
                                                                maxLength={15}
                                                                value={formData.mobile || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, mobile: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">E-mail*:</Label>
                                                            <Input type="text" className="form-control" placeholder="E-mail*"
                                                                maxLength={50}
                                                                value={formData.email || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, email: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Service Address:</Label>
                                                            <Input type="text" className="form-control" placeholder="Service Address"
                                                                maxLength={20}
                                                                value={formData.svcaddr || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, svcaddr: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Unit No.:</Label>
                                                            <Input type="text" className="form-control" placeholder="Unit No. (Landed property, enter: 00-00)"
                                                                maxLength={10}
                                                                value={formData.unitno || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, unitno: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col md="12">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Postal Code*:</Label>
                                                            <Input type="text" className="form-control" placeholder="Postal Code*"
                                                                value={formData.postcode || ''}
                                                                maxLength={6}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, postcode: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>

                                                    {/* <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Type Of Property:</Label>
                                                            <select className="form-control"
                                                                value={formData.propertytype || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, propertytype: e.currentTarget.value || '' })
                                                                }}>
                                                                <option value="">Type Of Property</option>
                                                                <option value="HDB">HDB</option>
                                                                <option value="Condo">Condo</option>
                                                                <option value="Landed">Landed</option>
                                                                <option value="Office">Office</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Size of Property:</Label>
                                                            <Input type="number" className="form-control" placeholder="Size of Property (sq ft)"
                                                                value={formData.propertysize || 0}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, propertysize: parseInt(e.currentTarget.value || 0) })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Number Of Bedrooms:</Label>
                                                            <select className="form-control"
                                                                value={formData.bedroom || 0}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, bedroom: parseInt(e.currentTarget.value || 0) })
                                                                }}>
                                                                <option value="">Number Of Bedrooms</option>
                                                                <option value="0">0 (Office)</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Number Of Bathrooms:</Label>
                                                            <select className="form-control"
                                                                value={formData.bathroom || 0}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, bathroom: parseInt(e.currentTarget.value || 0) })
                                                                }}>
                                                                <option value="">Number Of Bathrooms</option>
                                                                <option value="0">0 (Office)</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col> */}

                                                    

                                                    {/* <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Preferred Date:</Label>
                                                            <DateTime
                                                                timeFormat={false}
                                                                dateFormat="DD/MM/YYYY"
                                                                inputProps={{ placeholder: "DD/MM/YYYY" }}
                                                                closeOnSelect={true}
                                                                // value={new Date()}
                                                                value={formData.preferreddate || tomorrow}
                                                                isValidDate={e => validDate(e, undefined, 2)}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, preferreddate: e._d || tomorrow })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Require Equipment:</Label>
                                                            <select className="form-control"
                                                                value={formData.requireEquipment || false}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, requireEquipment: Boolean(e.currentTarget.value || false) })
                                                                }}>
                                                                <option value="">Require Equipment</option>
                                                                <option value="true">Yes please provide(Fees: $68.00) </option>
                                                                <option value="fasle">No(I will provide)</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md="6">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Preferred Start Time:</Label>
                                                            <select className="form-control"
                                                                value={formData.preferredtime || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, preferredtime: e.currentTarget.value || '' })
                                                                }}>
                                                                <option value="">Preferred Start Time</option>
                                                                <option value="1. Morning (9.00am – 1.00pm)">1. Morning (9.00am – 1.00pm)</option>
                                                                <option value="2. Afternoon (2.30pm – 6.30pm)"> 2. Afternoon (2.30pm – 6.30pm)</option>
                                                                <option value="3. Evening (7.30pm – 10.30pm)"> 3. Evening (7.30pm – 10.30pm)</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col> */}

                                                    <Col md="12">
                                                        <FormGroup className="text-left">
                                                            <Label className="badge badge-neutral">Message:</Label>
                                                            <TextareaAutosize
                                                                minRows={3}
                                                                maxRows={10}
                                                                maxLength={250}
                                                                value={formData.message || ''}
                                                                className="form-control" placeholder="Message"
                                                                onChange={e => {
                                                                    setFormData({ ...formData, message: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup check>
                                                            <Label check className="description text-left">
                                                                <Input type="checkbox"
                                                                    // defaultChecked={false}
                                                                    checked={formData.iagree || false}
                                                                    onChange={e => {
                                                                        setFormData({ ...formData, iagree: Boolean(e.currentTarget.value) })
                                                                    }}
                                                                ></Input>
                                                                <span className="form-check-sign"></span>
                                                            I agree to the collection and use of my personal data by Whissh (1 Plus Private Limited) in accordance with the Privacy Policy.
                                                    </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12" className="text-cenetr">
                                                        <FormGroup>
                                                            <ReCAPTCHA
                                                                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                                                                render="explicit"
                                                                verifyCallback={verifyCallback}
                                                                onloadCallback={reCaptchLoaded}
                                                                expiredCallback={reCaptchExpired}
                                                            />
                                                            <p className="description">
                                                                We will get in touch with you within the same working day!
                                                         </p>
                                                            <Button type="submit" className="btn btn-round btn-info">SUBMIT</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col md="4" className="ml-auto mr-auto text-left d-none d-sm-block">
                                    <img
                                        alt="Whissh Image"
                                        style={{
                                            maxHeight: "810px",
                                            objectFit: "cover"
                                        }}
                                        src={require("../../assets/img/img-joinus.jpg")}
                                    ></img>
                                </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    );
}

