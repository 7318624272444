import React, { useEffect } from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "../components/Headers/IndexHeader.js";
// import ModalChooseSerivice from "components/Login/ModalChooseSerivice.js";
// import DarkFooter from "components/Footers/DarkFooter.js";
// import ModalChooseLocationNewUser from "components/Login/ModalChooseLocationNewUser.js";

// sections for this page
import EasyStepSection from "../components/Home/EasyStepSection.js";
import CostomerSection from "../components/Home/CostomerSection.js";
import CountrySection from "../components/Home/CountrySection.js";
import BookNow from "../components/Home/BookNow.js";
import OurProject from "../components/Home/OurProject.js";
import FreeTextSection from "../components/Home/FreeTextSection"
import CustomerSatisfaction from "../components/Home/CustomerSatisfaction.js";

function Index() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  }, [])


  return (
    <>

      <IndexHeader />

        <div className="main">
          {/* <ModalChooseLocationNewUser /> */}
          <EasyStepSection />
          <CostomerSection />
          <CountrySection />
          <BookNow />
          <OurProject />
          <FreeTextSection/>
          {/* <CustomerSatisfaction /> */}
        </div>
      
    </>
  );
}

export default React.memo(Index);
