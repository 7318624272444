import React, { useEffect, useContext, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import StarRatingComponent from 'react-star-rating-component';
//help https://www.npmjs.com/package/react-star-rating-component
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Form
} from "reactstrap";
import ReCAPTCHA from "react-recaptcha";
// core components
import { GlobalContext } from '../../App';
import { PostAPI } from "../../API/APICalling";
import { ValidateEmail } from "../../utils/common";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constant";
export default function ServiceCrew() {
    const globalContext = useContext(GlobalContext);
    const [formData, setFormData] = useState({});
    const [captchaVerified, setCaptchaVerified] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    // specifying your onload callback function
    const reCaptchLoaded = async => {
        console.log('Done!!!!');
    };

    // specifying verify callback function
    const verifyCallback = async (response) => {
        if (response)
            setCaptchaVerified(true);
    };

    const reCaptchExpired = async (response) => {
        setCaptchaVerified(false);
    };
    const FnSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        if (!formData.firstname) {
            globalContext.notifications('error', 'First name is required.');
            return false;
        }
        if (!formData.lastname) {
            globalContext.notifications('error', 'Last name is required.');
            return false;
        }
        if (!formData.mobile) {
            globalContext.notifications('error', 'Mobile number is required.');
            return false;
        }

        if (!formData.email) {
            globalContext.notifications('error', 'Email address is required.');
            return false;
        }
        if (!ValidateEmail(formData.email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }
        if (!formData.postcode) {
            globalContext.notifications('error', 'Postal code is required.');
            return false;
        }
        if (!formData.iagree) {
            globalContext.notifications('error', 'You must agree to the privacy policy.');
            return false;
        }
        if (!captchaVerified) {
            globalContext.notifications('error', 'Please verify captcha.');
            return false;
        }
        else {
            if (formData) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`home/joinus`,
                    {
                        addr1: formData.addr1 || "",
                        addr2: formData.addr2 || "",
                        email: formData.email || "",
                        firstname: formData.firstname || "",
                        lastname: formData.lastname || "",
                        message: formData.message || "",
                        mobile: formData.mobile || "",
                        postcode: formData.postcode || ""
                    });
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        setFormData({});
                        globalContext.notifications('success', 'Your request has been sumitted successfully.');
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="adjust-navbar">
                <Container>
                    <Form onSubmit={FnSubmit}>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="11">
                                <Row className="pb-10">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <h2 className="big-heading">Be A Service Crew</h2>
                                        </div>
                                        <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                                <Row className="mb-15">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="card">
                                            <div className="card-header text-left title-2">
                                                Start earning money this week!
                                        </div>
                                            <div className="card-body">
                                                <Row>
                                                    <Col md="12" className="text-left">
                                                        <Label className="title-3">Gain access to hundreds of jobs in your city and build your own schedule.</Label><br />
                                                        <p className="description">This Information will be used to contact you about your service.</p>
                                                    </Col>
                                                    <Col md="12">
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="First Name*"
                                                                        maxLength={20}
                                                                        value={formData.firstname || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, firstname: e.currentTarget.value || '' })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="Last Name*"
                                                                        maxLength={20}
                                                                        value={formData.lastname || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, lastname: e.currentTarget.value || '' })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="Phone Number*"
                                                                        maxLength={15}
                                                                        value={formData.mobile || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, mobile: e.currentTarget.value || '' })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="E-mail*"
                                                                        maxLength={50}
                                                                        value={formData.email || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, email: e.currentTarget.value || '' })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/* <Col md="12">
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <select className="form-control">
                                                                    <option value="0">-Please Select Nationality-</option>
                                                                    <option value="1">abc</option>
                                                                    <option value="2">xyz</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                                    <Col md="12">
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="number" className="form-control" placeholder="Postal Code*"
                                                                        maxLength={6}
                                                                        value={formData.postcode || ''}
                                                                        onChange={e => {
                                                                            setFormData({ ...formData, postcode: e.currentTarget.value || '' })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Input type="text" className="form-control" placeholder="Address Line 1"
                                                                maxLength={100}
                                                                value={formData.addr1 || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, addr1: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Input type="text" className="form-control" placeholder="Address Line 2"
                                                                maxLength={100}
                                                                value={formData.addr2 || ''}
                                                                onChange={e => {
                                                                    setFormData({ ...formData, addr2: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md="12">
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <select className="form-control">
                                                                    <option value="0">-Please Select Country-</option>
                                                                    <option value="1">abc</option>
                                                                    <option value="2">xyz</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <TextareaAutosize
                                                                minRows={3}
                                                                maxRows={10}
                                                                maxLength={250}
                                                                value={formData.message || ''}
                                                                className="form-control" placeholder="Message"
                                                                onChange={e => {
                                                                    setFormData({ ...formData, message: e.currentTarget.value || '' })
                                                                }} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup check>
                                                            <Label check className="description text-left">
                                                                <Input type="checkbox"
                                                                    //checked={formData.iagree === true ? false : true}
                                                                    defaultChecked={formData.iagree || false}
                                                                    onChange={e => {
                                                                        setFormData({ ...formData, iagree: e.currentTarget.checked })
                                                                    }}
                                                                ></Input>
                                                                <span className="form-check-sign"></span>
                                                            I consent to having this website store my submitted information so Whissh can respond to my enquiry. I have read the Privacy Policy at the bottom of this page.
                                                    </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12" className="text-cenetr">
                                                        <FormGroup>
                                                            <ReCAPTCHA
                                                                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                                                                render="explicit"
                                                                verifyCallback={verifyCallback}
                                                                onloadCallback={reCaptchLoaded}
                                                                expiredCallback={reCaptchExpired}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <p className="description">
                                                                We will confirm your application within 24 hours. Thank you very much!
                                                         </p>
                                                            <Button type="submit" className="btn btn-round btn-info">SUBMIT</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="4" className="ml-auto mr-auto text-left d-none d-sm-block">
                                        <img
                                            alt="Whissh Image"
                                            style={{
                                                maxHeight: "810px",
                                                objectFit: "cover"
                                            }}
                                            src={require("../../assets/img/img-joinus.jpg")}
                                        ></img>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
            <div
                style={{
                    backgroundImage: "url(" + require("../../assets/img/bg-gray-additional.jpg") + ")",
                    padding: "20px 0px 20px",
                    backgroundRepeat: "round"
                }}
            >
                <Container>

                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="12">
                            <h2 className="big-heading">How It Works For You</h2>
                            <hr className="hr-large"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-success icon-circle">
                                    <img
                                        alt="1"
                                        src={require("../../assets/img/icon-join-1.png")}
                                    ></img>
                                </div>
                                <div className="details">
                                    <div className="title-2">You will receive a job</div>
                                    <p className="description">
                                        You will receive a notification and the details of your assigned job.
                                        </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-primary icon-circle">
                                    <img
                                        alt="2"
                                        src={require("../../assets/img/icon-account-booking.png")}
                                    ></img>
                                </div>
                                <div className="details">
                                    <div className="title-2">Accept and proceed to location</div>
                                    <p className="description">
                                        Our App is easy to use, accept the job and make your way to assigned job location.
                                        </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info info-hover">
                                <div className="icon icon-info icon-circle">
                                    <img
                                        alt="3"
                                        src={require("../../assets/img/icon-account-feedback.png")}
                                    ></img>
                                </div>
                                <div className="details">
                                    <div className="title-2">End job and get paid</div>
                                    <p className="description">
                                        You will get paid online and be able to view all payments on our app.
                                        </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="12">
                            <h2 className="big-heading">Our Srvice Crews Say</h2>
                            <hr className="hr-large"></hr>
                            <p className="title-2">We are pleased to share the testimonials of our satisfied servce crews.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4"
                            style={{ display: "inline-flex" }}>
                            <div className="quotes"><span>&#8220;</span></div>
                            <div>
                                <div className="ml-auto mr-auto text-center">
                                    <p className="title-3">Jane</p>
                                    <p className="description font-italic">Full time service crew</p>
                                    <p className="description">
                                        I used to work as freelance cleaner and had difficult sourcing
                                        for cleaning jobs as there was a lack of trust from homeowners
                                        towards freelancers like us. I am now financially more stable.
                                </p>
                                </div>
                                <div className="ml-auto mr-auto text-center" style={{ fontSize: 18 }}>
                                    <StarRatingComponent
                                        name="rate1"
                                        editing={false}
                                        starCount={5}
                                        value={4}
                                        starColor={`#ffb400`} /* color of selected icons, default `#ffb400` */
                                        emptyStarColor={`#cfd9e2`} /* color of non-selected icons, default `#333` */
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md="4"
                            style={{
                                display: "inline-flex",
                                borderLeft: "1px solid #f1f2f3",
                                borderRight: "1px solid #f1f2f3"
                            }}>
                            <div className="quotes"><span>&#8220;</span></div>
                            <div>
                                <div className="ml-auto mr-auto text-center">
                                    <p className="title-3">Jane</p>
                                    <p className="description font-italic">Full time service crew</p>
                                    <p className="description">
                                        I used to work as freelance cleaner and had difficult sourcing
                                        for cleaning jobs as there was a lack of trust from homeowners
                                        towards freelancers like us. I am now financially more stable.
                                </p>
                                </div>
                                <div className="ml-auto mr-auto text-center" style={{ fontSize: 18 }}>
                                    <StarRatingComponent
                                        name="rate1"
                                        editing={false}
                                        starCount={5}
                                        value={4}
                                        starColor={`#ffb400`} /* color of selected icons, default `#ffb400` */
                                        emptyStarColor={`#cfd9e2`} /* color of non-selected icons, default `#333` */
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md="4"
                            style={{ display: "inline-flex" }}>
                            <div className="quotes"><span>&#8220;</span></div>
                            <div>
                                <div className="ml-auto mr-auto text-center">
                                    <p className="title-3">Jane</p>
                                    <p className="description font-italic">Full time service crew</p>
                                    <p className="description">
                                        I used to work as freelance cleaner and had difficult sourcing
                                        for cleaning jobs as there was a lack of trust from homeowners
                                        towards freelancers like us. I am now financially more stable.
                                </p>
                                </div>
                                <div className="ml-auto mr-auto text-center" style={{ fontSize: 18 }}>
                                    <StarRatingComponent
                                        name="rate1"
                                        editing={false}
                                        starCount={5}
                                        value={4}
                                        starColor={`#ffb400`} /* color of selected icons, default `#ffb400` */
                                        emptyStarColor={`#cfd9e2`} /* color of non-selected icons, default `#333` */
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

