import React, { useContext } from "react";

import {
    Row,
    Col
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { ReadRate } from "../../utils/common";
import { PostAPI } from "../../API/APICalling";
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { removeData, getData } from "../../utils/localStorage";
import { GlobalContext } from '../../App';
import { FnCreateProductList } from "../../utils/ObjectListFormat";
import Datetime from "react-datetime";
// core components

export default React.memo(function PhysicalProducts(props) {
    // console.log(props)
    const history = useHistory();
    const sbContext = useContext(ServiceBookingContext);
    const globalContext = useContext(GlobalContext);
    let arnum, email;
    const onSubmit = async (jobnum, matname, matnum, price) => {
        // console.log(jobnum)
        // console.log(matname)
        // console.log(matnum)
        // console.log(price)
        if (history.location.state.displayaddon) {
            const jobnum1 = jobnum.split(';')[0];
            const jobnum2 = jobnum.split(';')[1];
            history.push({
                pathname: '/addon-listing',
                state: { 'jobnum': jobnum1, 'jobnum2': jobnum2, 'matname': matname, 'matnum': matnum, 'baseamt': price }
            })
        }
        else {
            const session = getData('session');
            if (!session) {
                globalContext.headerDispatch({ type: 'HEADERLOGIN' });
                //return false
            }
            else {
                history.push({
                    pathname: '/service-booking',
                    state: { redirect: 'BOOKING_INFORMATION','jobnum': jobnum, 'matname': matname, 'matnum': matnum, 'baseamt': parseFloat(price || 0) }
                })
                /* arnum = session.arnum;
                email = session.email;
                var productDetails = {
                    baseamt: parseFloat(price || 0),
                    jobnum: jobnum || '',
                    jobnum2: '',
                    matname: matname || '',
                    matnum: matnum || '',
                    startdate: Datetime.moment(),
                    _saveAddress: false
                }
                var result = await PostAPI(`booking/cart/${arnum}?email=${email}`, FnCreateProductList([productDetails], ''));
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        //sbContext.BookingDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else if (result.data) {
                        globalContext.notifications('success', 'Product has been added to cart');
                        removeData('transferredData');
                        removeData('questionnaire');
                    }
                } */
            }
        }
    }
    return (
        <>
            {
                props.data.map((item, index) => (
                    <React.Fragment key={index}>
                        <Row className="pt-10 pb-10">
                            <Col md="12" className="ml-auto mr-auto ">
                                <div className="text-center">
                                    <h2 className="big-heading mb-10 p-0">{item.matname}</h2>
                                    <h5 className="title-3 m-0 p-0">
                                        {ReadRate(item.rate).beforerate}<br></br>
                                        <span className="dollor">$</span>
                                        <span className="price">{ReadRate(item.rate).rate}</span><br></br>
                                        {ReadRate(item.rate).afterrate}
                                    </h5>
                                </div>
                                <hr className="hr-small"></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" sm="12" className="text-center">
                                <img
                                    alt="Whissh Image"
                                    className="package-listing-img-details"
                                    src={item.directory}
                                ></img>
                            </Col>
                            <Col md="8" sm="12">
                                {/* <div className="pt-15 text-left">
                                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                                    <p className="pl-25 description">
                                        Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
                                    </p>
                                    <b><span className="right-icon"></span>Kitchen</b>
                                    <p className="pl-25 description">
                                        Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
                                     </p>
                                    <b><span className="right-icon"></span>Bathroom</b>
                                    <p className="pl-25 description">
                                        Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
                                         </p>
                                </div> */}
                                <div className="pt-15 text-left" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                <div className="text-left">
                                    <button
                                        type="button"
                                        className="btn-round btn btn-info"
                                        onClick={e => {
                                            e.preventDefault();
                                            if (item.jobnum != "Coming Soon")
                                                onSubmit(item.jobnum, item.matname, item.matnum, item.price);
                                        }}
                                    >
                                        {item.jobnum == "Coming Soon" ? "Coming Soon" : "Add To Cart"}
                                        </button>
                                </div>

                            </Col>
                        </Row>
                    </React.Fragment>
                ))
            }
            {
                props.data.length !== 0 ?
                    <hr></hr> : null
            }

            {/* 
<Row className="pt-10 pb-10">
                            <Col md="8" className="ml-auto mr-auto ">
                                <div className="text-center">
                                    <h5 className="title-3 m-0 p-0">We've found this package that matches your needs!</h5>
                                    <h2 className="big-heading mb-10 p-0">Weekly Cleaning</h2>
                                    <h5 className="title-3 m-0 p-0">From <span className="dollor">$</span><span className="price">23</span> Per Hour</h5>
                                </div>
                                <hr className="hr-small"></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" sm="12" className="text-center">
                                <img
                                    alt="Whissh Image"
                                    className="package-listing-img-details"
                                    src={require("../../assets/img/img-sample-service2.jpg")}
                                ></img>
                            </Col>
                            <Col md="8" sm="12">
                                <div className="pt-15 text-left">
                                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                                    <p className="pl-25 description">
                                        Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
                                    </p>
                                    <b><span className="right-icon"></span>Kitchen</b>
                                    <p className="pl-25 description">
                                        Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
                                     </p>
                                    <b><span className="right-icon"></span>Bathroom</b>
                                    <p className="pl-25 description">
                                        Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
                                         </p>
                                </div>

                                <div className="text-left">
                                    <a href="/service-booking" className="btn-round btn btn-info">Book Now</a>
                                </div>

                            </Col>
                        </Row>
                    */}
        </>
    );
})
