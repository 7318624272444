import React from "react";
import { DateFormat, getGreetingTime, DateFormat2 } from "../../utils/common";
import { Row, Col } from "reactstrap";
import { TimeFormat } from "../../utils/common";
import { ConvertUrlToFileName } from "../../utils/common";
export default React.memo(function MBServiceInfo(props) {
    function GetInvoiceDisplay(docurl)
    {
        let urls = docurl.split(';')
        return(<>{urls.map((item) =>(<><a href={item} className="btn-link pl-0" target="_blank">{ConvertUrlToFileName(item)}</a><br/></>))}</>)
    }

    return (
        <>
            {props.data &&
                <React.Fragment>
                    {
                        props.data.length < 1 ?
                            <div className="text-center empty-screen">No Records Found</div>
                            :
                            props.data.map((item, index) => (
                                <div className="card-body" key={index}>
                                    <Row className="pb-15">
                                        <Col lg="6" md="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Ticket No.
                                                                         </div>
                                            <div className="description">
                                                {item.ticket}
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Created On
                                                                            </div>
                                            <div className="description">
                                                {DateFormat(item.createdon, 'ddd, DD-MMM-YYYY')}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Service Type
                                                            </div>
                                            <div className="description">
                                                {item.servicetype}
                                            </div>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                No. of Crews
                                                            </div>
                                            <div className="description">
                                                {item.crewqty}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Service No.
                                                            </div>
                                            <div className="description">
                                                {item.serviceno}
                                            </div>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Price
                                                            </div>
                                            <div className="description">
                                                $     {parseFloat(item.price || 0).toFixed(2)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="12" lg="12" sm="12" xs="12">
                                            <div className="text-left font-weight-bold">
                                                Property Type
                                                            </div>
                                            <div className="description">
                                                {item.propertytype}({item.area} sqft)
                                                             </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                No. of Bedroom
                                                            </div>
                                            <div className="description">
                                                {item.bedroomqty}
                                            </div>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                No. of bathroom
                                                            </div>
                                            <div className="description">
                                                {item.bathroomqty}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="12" lg="12" sm="12" xs="12">

                                            <div className="text-left font-weight-bold">
                                                Service Address
                                                                                            </div>
                                            <div className="description">
                                                {/* 123 Street Name #01-01 Singapore 987654 */}
                                                {item.shipaddress}
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Service Date
                                                                                     </div>
                                            <div className="description">
                                                {DateFormat2(item.servicedate, 'ddd, DD-MMM-YYYY')}
                                            </div>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <div className="text-left font-weight-bold">
                                                Time Slot
                                                                                     </div>
                                            <div className="description">
                                                {getGreetingTime(item.timestart, item.timeend)}<br />
                                                {TimeFormat(item.timestart)} - {TimeFormat(item.timeend)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="12" lg="12" sm="12" xs="12">
                                            <div className="text-left font-weight-bold">
                                                Invoice
                                             </div>
                                            <div className="description">
                                                {item.docurl && !item.docurl.includes('http') && <>{item.docurl}</>}
                                                {item.docurl && item.docurl.includes('http') && GetInvoiceDisplay(item.docurl)}
                                                {!item.docurl && <>Invoice is being generated. Please refresh in a few seconds.</>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-15">
                                        <Col md="12" lg="12" sm="12" xs="12">
                                            <div className="text-left font-weight-bold">
                                                Progress
                                             </div>
                                            <div className="description" style={{ whiteSpace: "pre-line" }}>
                                                {item.progress}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                    }
                </React.Fragment>
            }
        </>
    )
});