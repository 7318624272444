export const BlogInitialState = {
    BlogList: [],
    FilterBlogList: [],
    categoryddl: [],
}

export const BlogReducer = (state, action) => {
    switch (action.type) {
        case 'GET_SUCCESS': {
            let ddl = action.payload.map(item => {
                return item.category;
            });
            var tree = [];
            [...new Set(ddl)].forEach(element => {
               // console.log(element)

                let year = []
                action.payload.forEach(item => {
                    if (element === item.category)
                        year.push(new Date(item.blogDate).getFullYear());
                });
                //console.log(year);

                var tree2 = [];
                [...new Set(year)].forEach(item => {

                    let month = []
                    action.payload.forEach(item => {
                        if (element === item.category && new Date(item.blogDate).getFullYear() === item)
                            month.push(parseInt(new Date(item.blogDate).getMonth() + 1 || 1));
                    });
                    //console.log(month);

                    var tree3 = [];
                    [...new Set(month)].forEach(item => {
                        tree3.push({ key: item, label: item });
                    });
                    tree2.push({ key: item, label: item, nodes: tree3 });
                });

                tree.push({ key: element, label: element, nodes: tree2 });

            });

            return {
                ...state,
                FacebookPlugin: false,
                BlogList: action.payload,
                FilterBlogList: action.payload,
                LeftMenu: tree
                //categoryddl: [...new Set(ddl)]
            }
        }

        case 'RESET': {
            return {
                ...state,
                FacebookPlugin: false,
                FilterBlogList: state.BlogList
            }
        }
        case 'FILTER_RESULT': {
            return {
                ...state,
                FacebookPlugin: false,
                FilterBlogList: action.value ? (state.BlogList.filter((item) =>
                    (item.category.toLowerCase().includes(action.value.toLowerCase())) && item
                )) : state.BlogList
            }
        }

        case 'FILTER': {
            return {
                ...state,
                FacebookPlugin: false,
                FilterBlogList: action.category ? (state.BlogList.filter((item) =>
                    (item.category.toLowerCase().includes(action.category.toLowerCase())) && item
                )) : state.BlogList
            }
        }
        case 'FILTER_YEAR': {
            return {
                ...state,
                FacebookPlugin: false,
                FilterBlogList: state.BlogList.filter((item) =>
                    (item.category.toLowerCase().includes(action.category.toLowerCase()) && new Date(item.blogDate).getFullYear() === parseInt(action.year || 0)) && item
                )
            }
        }
        case 'FILTER_MONTH': {
            return {
                ...state,
                FacebookPlugin: false,
                FilterBlogList: state.BlogList.filter((item) =>
                    (item.category.toLowerCase().includes(action.category.toLowerCase()) && new Date(item.blogDate).getFullYear() === parseInt(action.year || 0) && parseInt(new Date(item.blogDate).getMonth() + 1 || 1) === parseInt(action.month || 0)) && item
                )
            }
        }
        case 'ERROR': {
            return {
                ...state,
                BlogList: [],
                FilterBlogList: [],
                FacebookPlugin: false
            }
        }
        case 'FACEBOOK': {
            return {
                ...state,
                FilterBlogList: [],
                FacebookPlugin: true
            }
        }
        default: {
            return {
                ...state,
                FacebookPlugin: false
            }
        }
    }
}