import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import { gtagConversionEvent } from "../utils/common";
import ReactPixel from 'react-facebook-pixel'
import {
    loadStripe,
} from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY, STRIPE_PUBLIC_KEY_3Y } from "../constant";

// core components
// import OtherCustomerServicePackage from "components/PackageList/OtherCustomerServicePackage.js"

function PaymentResult() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const stripeFn = async () => {
            const slug = window.location.pathname.substring(
                window.location.pathname.lastIndexOf("/") -2
            );
            var key = STRIPE_PUBLIC_KEY;
            if (slug == '3y/')
                key = STRIPE_PUBLIC_KEY_3Y;
            const stripe = await loadStripe(key);
            const clientSecret = new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            );

            if (!clientSecret) {
                return;
            }

            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setSuccess(true);
                        setMessage("Payment succeeded!");
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            });
        };

        stripeFn();

    }, []);

    return (
        <>
            <div id="PaymentResult" className="adjust-navbar">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">

                            <Row className="pb-15">
                                <Col md="8" className="ml-auto mr-auto ">
                                    <div className="text-center">
                                        <img
                                            alt="Whissh Image"
                                            src={require("../assets/img/icon-login.png")}
                                        ></img>
                                    </div>
                                    {success ? (
                                        <React.Fragment>
                                            <div className="text-center">
                                                <h2 className="big-heading p-5">Payment Successful !</h2>
                                                <b className="title-3 p-10">{message}</b><br />
                                            </div>
                                            <hr className="hr-large"></hr>
                                            <Button
                                                className={"btn btn-round btn-info"}
                                                to="/my-account/my-booking" tag={Link}
                                            >
                                                VIEW BOOKING
                                            </Button>
                                        </React.Fragment>
                                    ) :
                                        (
                                            <React.Fragment>
                                                <div className="text-center">
                                                    <b className="title-3 p-10">{message}</b>
                                                    {/* <b className="title-3 p-10">Your Transaction Id: {txnId}</b> */}
                                                </div>
                                                <div className="text-center">
                                                    <Link
                                                        className="btn btn-round btn-default"
                                                        to={{
                                                            pathname: '/service-booking',
                                                            state: { 'redirect': 'FINALIZE' }
                                                        }}
                                                    >
                                                        My Cart
                                                    </Link>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </Col>
                            </Row>

                            {/* <OtherCustomerServicePackage para={{ title: "Customers who booked your service also booked these" }} /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PaymentResult;
