import React, { useEffect, useContext } from "react";
import StarRatingComponent from 'react-star-rating-component';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, FormGroup, Input, Button, ModalBody, ModalHeader, Modal } from "reactstrap";
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { GetAPI } from "../../API/APICalling";
import { DateFormat } from "../../utils/common";

export default function MyFeedback() {
    const [modal, setModal] = React.useState(false);
    const globalContext = useContext(GlobalContext);
    const [feedbackList, setFeedbackList] = React.useState([]);
    const [feedbackDetails, setFeedbackDetails] = React.useState({});
    const history = useHistory();

    useEffect(() => {
        FnGetFeedbackList();
    }, [])

    const FnGetFeedbackList = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myfeedback/${session.arnum}?email=${session.email}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    //console.log(result.data)
                    setFeedbackList(result.data)
                }
            }
        }
    };

    const FnGetFeedbackDetails = async (refnum) => {
        setFeedbackDetails({});
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`/myfeedback/details/${refnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    //console.log(result.data)
                    setFeedbackDetails(result.data);
                    setModal(true);
                }
            }
        }
    };

    const IsSelected = (e) => {
        if (feedbackDetails.items) {
            var result = feedbackDetails.items.filter(item => {
                return item === e
            });
            if (result.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };

    return (
        <>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                    </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                My Feedback
                            </li>
                        </ol>
                    </Container>
                </div>
                <div className="myaccount-header">
                    <MyAccountHeader />
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row className="pt-30">
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <h2 className="big-heading">My Feedback</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col md="12" className="ml-auto mr-auto">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive table-list-view" id="myFeedbackTable">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Ticket No.</th>
                                                                    <th className="text-center">Service Date</th>
                                                                    <th className="text-left">Service type</th>
                                                                    <th className="text-left">Assigned Crew</th>
                                                                    <th className="text-center">Rating</th>
                                                                    {/* <th className="text-left">Tip Amount</th> */}
                                                                    <th className="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {feedbackList &&
                                                                    <React.Fragment>
                                                                        {
                                                                            feedbackList.length < 1 ?
                                                                                <tr>
                                                                                    <td colSpan="6" className="text-center empty-screen">No Records Found</td>
                                                                                </tr>
                                                                                :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        feedbackList.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td className="text-center">{item.ticket}</td>
                                                                                                <td className="text-center">{DateFormat(item.date,'DD-MMM-YYYY')}</td>
                                                                                                <td className="text-left">{item.servicetype}</td>
                                                                                                <td className="text-left contactName">{item.crew}</td>
                                                                                                <td className="text-center pt-0 pb-0">
                                                                                                    <div className="ml-auto mr-auto" style={{ fontSize: 24 }}>
                                                                                                        <StarRatingComponent
                                                                                                            name="rate1"
                                                                                                            editing={false}
                                                                                                            starCount={6}
                                                                                                            value={item.rating}
                                                                                                            starColor={`#B8850A`}
                                                                                                            emptyStarColor={`#FFE4C4`}
                                                                                                        /> </div>
                                                                                                </td>
                                                                                                <td className="text-center pt-5 pb-5">
                                                                                                    <span className="pr-10">
                                                                                                        <a href={`/my-request-quotation/${item.refnum}`}
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                FnGetFeedbackDetails(item.refnum);
                                                                                                            }
                                                                                                            }>
                                                                                                            <img
                                                                                                                alt="Whissh Image"
                                                                                                                className="rounded-circle"
                                                                                                                src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                                                style={{ maxHeight: "18px", paddingRight: "10px" }}
                                                                                                            ></img>
                                                                                                        </a>
                                                                                                    </span>
                                                                                                    {/* <span>
                                                                                                        <a href='#'
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                FnDeleteQuote(item.refnum);
                                                                                                            }}>
                                                                                                            <img
                                                                                                                alt="Whissh Image"
                                                                                                                className="rounded-circle"
                                                                                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                                                                                style={{ maxHeight: "14px" }}
                                                                                                            ></img>
                                                                                                        </a>
                                                                                                    </span> */}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                modal ? (
                    <Modal size="lg" isOpen={modal}
                        className="transparent-modal"
                    // toggle={() => setModal(false)}
                    >
                        <div className="submit-feedback-modal">
                            <div className="col-md-11">
                                <ModalHeader toggle={() => setModal(false)}>
                                </ModalHeader>
                            </div>
                            <ModalBody className="p-0 text-center">
                                <div className="card col-md-10">
                                    <div className="card-header text-center title-2">
                                        Send us your feedback
                                     </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="12">
                                                <p className="title-3">Rate your crew</p>
                                                <div className="ml-auto mr-auto" style={{ fontSize: 50, lineHeight: "1" }}>
                                                    <StarRatingComponent
                                                        name="rating"
                                                        editing={true}
                                                        starCount={6}
                                                        value={feedbackDetails.rating}
                                                        starColor={`#B8850A`}
                                                        emptyStarColor={`#FFE4C4`}
                                                    /> </div>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col md="12" className="text-left">
                                                <p className="title-3">Enjoyed your service?</p>
                                                <p className="description">Consider giving our crew a tip</p>
                                                <Row>
                                                    <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                                        <div
                                                            className={feedbackDetails.tipamt === 20 ? "topup-box active" : "topup-box"}> $20</div>
                                                    </Col>
                                                    <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                                        <div className={feedbackDetails.tipamt === 30 ? "topup-box active" : "topup-box"}> $30</div>
                                                    </Col>
                                                    <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                                        <div
                                                            className={feedbackDetails.tipamt === 40 ? "topup-box active" : "topup-box"}> $40</div>
                                                    </Col>
                                                    <Col lg="3" md="3" sm="3" xs="3" className="p-5">
                                                        <div className={feedbackDetails.tipamt === 100 ? "topup-box active" : "topup-box"}> $100</div>
                                                    </Col>
                                                </Row>
                                                <p className="title-3">Overall experience</p>
                                                <p className="description">Let your service crew know too!</p>
                                                <Row>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("clean") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample1.png")}
                                                            ></img>
                                                            <b>Clean & Comfy</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("thorough") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample2.png")}
                                                            ></img>
                                                            <b>Throrough & Meticulous</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("helpful") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample3.png")}
                                                            ></img>
                                                            <b>Helpful & Approachable</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("eco") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample4.png")}
                                                            ></img>
                                                            <b>Eco-friendly Products</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("safe") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample5.png")}
                                                            ></img>
                                                            <b>Safe & Careful</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="6" sm="6" xs="6" className="text-center p-5">
                                                        <div
                                                            className={IsSelected("professional") ? "btn-feedback active" : "btn-feedback"}>
                                                            <img
                                                                alt="Whissh Image"
                                                                src={require("../../assets/img/icon-badge-sample6.png")}
                                                            ></img>
                                                            <b>Professional & Pleasant</b>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Input type="textarea" className="form-control" placeholder="Write remarks"
                                                                readOnly={true}
                                                                value={feedbackDetails.remark} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="card-footer text-center">
                                        <Button
                                            className={"btn btn-round btn-default"}
                                            onClick={e => {
                                                e.preventDefault();
                                                setModal(false);
                                            }}
                                        >CANCEL
                                     </Button>
                                    </div>
                                </div>
                            </ModalBody>
                        </div>

                    </Modal>
                ) : null
            }
        </>
    );
}
