import React, { useContext, useEffect, useState } from 'react';
import { ModalBody, ModalHeader, Row, Col, ModalFooter } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import API from '../../utils/api'
import Loader from '../../components/Login/Loader';
import { useHistory } from 'react-router-dom';
import { removeData } from '../../utils/localStorage';
import { GetAPI } from '../../API/APICalling';
import { getAlt,getLink } from 'utils/common.js';

function ModalSubCategory(props) {
  const globalContext = useContext(GlobalContext);
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);
  const history = useHistory();


  useEffect(() => {
    removeData('questionnaire');
    removeData('transferredData');
    removeData('transferredAddon');

    const fetchData = async e => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`home/services/detailII/${globalContext.headerState.subsvcnum}`);
      if (result) {
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data);
        }
        setIsFetching(false);
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
      }


      // API.get(`home/services/detailII/${globalContext.headerState.subsvcnum}`)
      //   .then(res => {
      //     const response = res.data;
      //     if (response != null) {
      //       if (response.meta.responseCode === 'S') {
      //         setData(response.data);
      //         setIsFetching(false);
      //       }
      //       else {
      //         globalContext.notifications('error', response.meta.message);
      //         setIsFetching(false);
      //       }
      //     }
      //     else {
      //       globalContext.notifications('error', 'Oops ! Something went wrong.');
      //       setIsFetching(false);
      //     }
      //   })
      //   .catch(error => {
      //     globalContext.notifications('error', 'Oops ! Something went wrong.');
      //     setIsFetching(false);
      //     console.log(error)
      //   });
    };
    fetchData();
  }, []);


  const FnQuestionDecision = (matcatnum, skipqn, displayaddon, btnlink) => {
    // console.log("matcatnum: " + matcatnum)
    // console.log("skipqn: " + skipqn)
    // console.log("displayaddon: " + displayaddon)

    if (btnlink) {
      globalContext.headerDispatch({ type: 'CLOSE' });
      history.push({
        pathname: btnlink,
      });
    }
    else {
      if (globalContext.authState.isLoggedIn) {
        if (skipqn) {
          history.push({
            pathname: '/package-listing',
            state: { 'matcatnum': matcatnum, 'displayaddon': displayaddon }
          })
        }
        else {
          globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: matcatnum })
        }
      }
      else {
        globalContext.headerDispatch({ type: 'CHOOSE_SERVICE', matcatnum: matcatnum, skipqn: skipqn, displayaddon: displayaddon });
      }
    }
  };

  return (
    <>
      <div className="menu-modal">
        <ModalHeader toggle={() => globalContext.headerDispatch({ type: 'CLOSE' })}>
          {
            data.header_desc !== '' &&
            (
              <Row className="header">
                <Col md="6" sm="12">
                  <img
                    alt={data.header_img? getAlt(data.header_img) : 'Whissh Image'}
                    src={data.header_img? getLink(data.header_img) : ''}
                  ></img>
                </Col>
                <Col md="6" sm="12" dangerouslySetInnerHTML={{ __html: data.header_desc }}></Col>

                {/* <Col md="6" sm="12">
              <div className="text-left">
                <h4 className="menu-title">{globalContext.headerState.menuTitle}</h4>
                <p className="description">
                  You’ve got things to do that are more important than cleaning a messy house.
              </p>
                <p className="description">
                  Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
              </p>
              </div> 
            </Col>*/}
              </Row>
            )
          }

        </ModalHeader>
        <ModalBody>
          {data.serviceDetails &&
            <div>
              {
                data.serviceDetails.length === 0 ? (
                  <h5 className="text-center text-danger">
                    No Record Found
                  </h5>
                )
                  : (
                    data.serviceDetails.map((item, index) => (
                      <Row key={index}>
                        <Col md="10" className="ml-auto mr-auto">

                         {/*  <div className="text-left">
                            <h5 className="menu-title">{item.wname}</h5>
                            <p className="description">
                              Certain occasions demand that you seek professional help for cleaning your house
                            </p>
                          </div>
                          
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
</p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
</p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
</p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
</p>
                  </div> */}

                          <div dangerouslySetInnerHTML={{ __html: item.wdesc }}></div>

                          <div className="text-left">
                            <button
                              type="button" className="btn-round btn btn-info">
                              <i className="fas fa-bell"></i>
                              <span className="bookNow" onClick={() => FnQuestionDecision(item.matcatnum, item.skipqn, item.displayaddon, item.btnlink)}>Book Now</span>
                            </button>
                          </div>
                          <hr></hr>
                        </Col>
                      </Row>
                    )))
              }

              {/* 
              <Row>
                <Col md="10" className="ml-auto mr-auto ">
                  <div className="text-left">
                    <h5 className="title-2">Move In/Out Cleaning</h5>
                    <p className="description">
                      Certain occasions demand that you seek professional help for cleaning your house
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
            </p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
            </p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
            </p>
                  </div>

                  <div className="text-left">
                    <button
                      type="button" className="btn-round btn btn-info">
                      <i className="fas fa-bell"></i>
                      <span className="bookNow" onClick={() => globalContext.headerDispatch({ type: 'CHOOSE_SERVICE' })}>Book Now</span>
                    </button>
                  </div>
                  <hr></hr>
                </Col>
              </Row>
              <Row>
                <Col md="10" className="ml-auto mr-auto ">
                  <div className="text-left">
                    <h5 className="title-2">Post Renovation Cleaning</h5>
                    <p className="description">
                      Certain occasions demand that you seek professional help for cleaning your house
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
            </p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
            </p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
            </p>
                  </div>

                  <div className="text-left">
                    <button
                      type="button" className="btn-round btn btn-info">
                      <i className="fas fa-bell"></i>
                      <span className="bookNow" onClick={() => globalContext.headerDispatch({ type: 'CHOOSE_SERVICE' })}>Book Now</span>
                    </button>
                  </div>
                  <hr></hr>
                </Col>
              </Row> */}

            </div>
          }
          <div className="text-center">
            <button type="button" className="btn btn-round btn-default"
              onClick={e => {
                e.preventDefault();
                globalContext.headerDispatch({ type: 'CLOSE' })
              }}
            >CANCEL</button>
          </div>
        </ModalBody>
      </div>
    </>
  );
}

export default ModalSubCategory;