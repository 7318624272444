import React, { useContext, useEffect, useState, useReducer } from "react";
import { Container, Row, Col, Button, FormGroup, Input } from "reactstrap";

import MostRecentServicePackage from "../components/PackageList/MostRecentServicePackage .js"
import MostMatchingServicePackage from "../components/PackageList/MostMatchingServicePackage.js"
import MostPopularServicePackage from "../components/PackageList/MostPopularServicePackage.js"
import OtherCustomerServicePackage from "../components/PackageList/OtherCustomerServicePackage.js"

import { useHistory } from "react-router-dom";
import { GlobalContext } from '../App.js';
import { getData } from "../utils/localStorage";
import { PackageListingReducer, PackageListingInitialState } from "../reducer/bookingReducer.js";
import { PostAPI } from "../API/APICalling";
import PhysicalProducts from "components/PackageList/PhysicalProducts.js";
import {gtagConversionEvent} from "../utils/common"
import ReactPixel from 'react-facebook-pixel'


export default function NewCustomerPackageList(props) {
    const history = useHistory();
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(PackageListingReducer, PackageListingInitialState)
    let questionnaire = [];;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        gtagConversionEvent("AW-730252635/m0_qCPzu8c8CENuKm9wC");
        ReactPixel.track('AddToCart');
        globalContext.headerDispatch({ type: 'CLOSE' });
        questionnaire = getData('questionnaire');
        // console.log(questionnaire);
        const session = getData('session')
        if (!session) {
            //globalContext.notifications('error', 'Your session has been expired, Please login again.');
            //history.push("/");
            FnGetPackages("a", "a", props.location.state.matcatnum, questionnaire);
        }
        else if (!props.location.state) {
            globalContext.notifications('error', 'Sorry, You can not access this page directly, Please try again.');
            history.push("/");
        }
        else {

            FnGetPackages(session.arnum, session.email, props.location.state.matcatnum, questionnaire);

        }
    }, []);

    const FnGetPackages = async (arnum, email, catnum, questionnaire) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/${arnum}/packages?email=${email}&catnum=${catnum}`, questionnaire === null ? [] : questionnaire);
        if (result) {
            if (result.error) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                FnfilterData(result.data);
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            }
        }
    };



    const FnfilterData = async data => {
        const header = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'Header'.toLowerCase()) && item
        });
        dispatch({ type: 'SETDATA', key: 'header', data: header })
        const bestMatched = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'BestMatched'.toLowerCase()) && item
        });
        dispatch({ type: 'SETDATA', key: 'bestMatched', data: bestMatched })

        const previouslyBooked = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'PreviouslyBooked'.toLowerCase()) && item
        });

        dispatch({ type: 'SETDATA', key: 'previouslyBooked', data: previouslyBooked })
        const popular = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'Popular'.toLowerCase()) && item
        });

        dispatch({ type: 'SETDATA', key: 'popular', data: popular })
        const other = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'Other'.toLowerCase()) && item
        });
        dispatch({ type: 'SETDATA', key: 'other', data: other })
        const products = data.filter(item => {
            return (item.pType.toLowerCase().trim() === 'Products'.toLowerCase()) && item
        });
        dispatch({ type: 'SETDATA', key: 'products', data: products })

        return true;
    }


    return (
        <>
            <div id="PackageList" className="adjust-navbar">

                <React.Fragment>
                    {
                        state.header.map((item, index) => (
                            <Container fluid key={index}>
                                <Row className="pt-15">
                                    <Col className="ml-auto mr-auto text-center" md="12">
                                        <h2 className="big-heading">{item.matname}</h2>
                                        <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                                <Row className="package-listing-heading">
                                    <Col md="6" sm="12" xs="12">
                                        <img
                                            alt="Whissh Image"
                                            className="pull-right"
                                            src={item.directory || require("../assets/img/img-sample-service2.jpg")}
                                        ></img>
                                    </Col>
                                    <Col md="4" sm="12" xs="12">
                                        <div className="text-left pt-20" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                        {/*<div className="text-left pt-20">
                                                 <h5 className="title-2">Cleaning Scope</h5>
                                                <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                                                <p className="pl-25 description">
                                                    Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
                                    </p>
                                                <b><span className="right-icon"></span>Kitchen</b>
                                                <p className="pl-25 description">
                                                    Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
                                     </p>
                                                <b><span className="right-icon"></span>Bathroom</b>
                                                <p className="pl-25 description">
                                                    Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
                                         </p> 
                                            </div>*/}

                                    </Col>
                                </Row>
                            </Container>
                        ))
                    }
                    < Container >
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="12">
                                {
                                    state.previouslyBooked.length > 0 && <MostRecentServicePackage data={state.previouslyBooked} />
                                }{
                                    state.products.length>0 && <PhysicalProducts data={state.products}/>
                                }{
                                    state.bestMatched.length ==0 && state.products.length == 0 && <p>Please call our office for assistance.</p>
                                }{                        
                                    state.bestMatched.length > 0 && <MostMatchingServicePackage data={state.bestMatched} />
                                }{
                                    state.popular.length > 0 && <MostPopularServicePackage data={state.popular} />
                                }{
                                    !state.showother &&
                                    (state.other.length > 0) &&
                                    <Button type="button" className="btn-round btn btn-info mb-30"
                                        onClick={e => {
                                            e.preventDefault();
                                            // setshowother(true);
                                            dispatch({ type: 'FEILD', field: 'showother', value: true })
                                        }
                                        }
                                    >
                                        <span className="bookNow">SHOW MORE RELATED PRODUCTS</span>
                                    </Button>
                                }
                                {state.showother &&
                                    state.other.length > 0 && <OtherCustomerServicePackage para={{ title: "You can also consider these services", data: state.other }} />
                                }

                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>

            </div>
        </>
    );
}
