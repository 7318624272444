import React, { useState, useEffect, useContext} from "react";
import { Container, Row, Col,Input } from "reactstrap";
import { Link} from "react-router-dom";
import Carousel from 'react-multi-carousel';
//import '../../assets/css/react-multi-carousel.css';
import 'react-multi-carousel/lib/styles.css';
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { useHistory } from "react-router-dom";


/*const image_url = [
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
  },
  {
    name: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
  },

];
*/

/*function Scrolling() {
  //const [list, setItems] = useState([]);

  //setTimeout(() => {
  //  setItems(list.concat(image_url));
  //}, 10);

  const [isOver, setIsOver] = useState(true);
  const [count, setCount] = useState(4);
  //const [listItems, setListItems] = useState(image_url.slice(0, 20));
  const [listItems, setListItems] = useState(image_url);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    //setListItems(image_url);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  });

  function handleScroll() {

    //window.innerHeight + document.documentElement.scrollTop !==
    //  document.documentElement.offsetHeight ||

    if ((document.body.scrollTop || document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) < 0.8 || isFetching)
      return;
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    if (listItems.length >= 200) {
      setIsOver(false);
    }
    if (isOver) {
      setCount(count + 20);
      setTimeout(() => {
        //ste
        //setListItems(listItems.concat(list.slice(count, count + 20)));
        setListItems(listItems.concat(image_url));
        setIsFetching(false);
      }, 200);
    }
  }
  return (
    <div>
      <Row>
        {listItems.map((item, index) => (
          //{image_url.map((item,index) => (
          <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-6">
            <div className="addcart-content addcart-block">
              <Link to={item === "" ? "#" : `blog/${""}`}>
                <div className="shop-cover-img">
                  <img src={item.name} alt={""} />
                </div>
              </Link>
              <div className="blog-text">
                <Link to={item === "" ? "#" : `blog/${""}`}>
                  <span className="dollor">$</span>
                  <span className="price">23</span>
                </Link>
                <p
                  className="blog-desc description"
                  dangerouslySetInnerHTML={{
                    __html:
                      "Viruses are sometimes described as 'organisms at the edge of life'.",
                  }}
                ></p>
                <div className="button-center">
                  <Link
                    className="btn btn-round btn-info"
                    to={item.name === "" ? "#" : `blog/${""}`}
                  >
                    Add To Cart
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Row>
      <div align="center">
        <h4>{isFetching && isOver && "Loading..."}</h4>
      </div>
    </div>
  );
};
*/


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const items = [
  {
    Item1: 'Cleaning Tools',

  },
  {
    Item1: 'All Purpose Cleaner',
  },
  {
    Item1: 'Surface Care & Protection',
  },
  {
    Item1: 'Cleaning Tools',
  },
  {
    Item1: 'All Purpose Cleaner',
  },
  {
    Item1: 'Surface Care & Protection',
  },
  {
    Item1: 'General Cleaning',
  }
];

function CategorySection(props) {

  const CategoryItems = items.map((item) => (
    <div key={item.toString()}>
      <div className="shop-corousel">
        <div className="shop-box">
          <div className="shop-box-content">
            <div className="shop-text">{item.Item1}</div>
          </div>
        </div>
      </div>

    </div>
  ));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])
  return (<>
    <div >
      <div className="features-3 pt-0">
        <div className="shop" style={{ backgroundColor: "#f2f2f2a1" }}>
          <div className="shop-body">
            <Container>
              <Row>
                <Col>
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={false}
                    arrows={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    //removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={"mobile"}
                    dotListClass="custom-dot-list-style"
                  //itemClass="carousel-item-padding-40-px"
                  >
                    {CategoryItems}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

const mydata=[
  {
    catname: "",
    imageurl: "",
    matname: "",
    matnum: "",
    price: 0,
    redirecturl: "",
    stockqty: 0
  }  
]



export default function Shop() {
  const globalContext = useContext(GlobalContext);
  const history = useHistory();
  const [data, setData] = useState(mydata); 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetProduct = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`shop/productlist`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          console.log(result.data);
          if (!result.data) { history.push("/shop"); }
          else
            setData(result.data)          
        }
        else {
          history.push("/shop");
         }
      }
    }
    FnGetProduct();
  }, []);


  //Scrolling region//
  /*const [isOver, setIsOver] = useState(true);
  const [count, setCount] = useState(4);
  //const [listItems, setListItems] = useState(image_url.slice(0, 20));
  const [listItems, setListItems] = useState(image_url);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    //setListItems(image_url);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  });

  function handleScroll() {

    //window.innerHeight + document.documentElement.scrollTop !==
    //  document.documentElement.offsetHeight ||

    if ((document.body.scrollTop || document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) < 0.8 || isFetching)
      return;
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    if (listItems.length >= 200) {
      setIsOver(false);
    }
    if (isOver) {
      setCount(count + 20);
      setTimeout(() => {
        //setListItems(listItems.concat(list.slice(count, count + 20)));
        setListItems(listItems.concat(image_url));
        setIsFetching(false);
      }, 200);
    }
  }
*/
//Scrolling Region End//
const num=0;
  return (
    <div className="adjust-navbar-breadcrumb">
      <div className="breadcrumb-outer">
        <Container className="breadcrumb-body">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                Shop
              </li>
          </ol>
        </Container>
      </div>
      <Container className="container-fluid">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <div className="big-heading">Shop</div>
            <hr className="hr-large"></hr>
          </Col>
        </Row>
        <Row>
          <Col>
            <CategorySection />
          </Col>
        </Row>
        <Row className="div-center">
          <Col md="12">
            <div>
              <Row>
                {data.map((item, index) => (
                  //{image_url.map((item,index) => (
                  <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="addcart-content addcart-block">
                      <Link to={item === "" ? "#" : `blog/${""}`}>
                        <div className="product-image-box">
                          <img className="product-image" src={item.imageurl} alt={""} />
                        </div>
                      </Link>
                      <div className="blog-text">
                        <Link to={item === "" ? "" : `blog/${""}`}>
                <div className="material-name">{item.matname}</div>
                <div>
                <span className="dollor">$</span>
                <span className="price">{item.price}</span>
                </div>
                          {item.stockqty > 0 &&

                            <Input type="select" label="Select" className="quantity-dropdown" >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </Input>
                          }
                {item.stockqty==0 &&
                  <div className="material-available">Out of Stock</div>
                }
                
                        </Link>
                        <p
                          className="blog-desc description"
                          dangerouslySetInnerHTML={{
                            __html:
                              "Viruses are sometimes described as 'organisms at the edge of life'.",
                          }}
                        ></p>
                        <div className="button-center">
                          <Link
                            className="btn btn-round btn-info"
                            to={item.name === {} ? "#" : `blog/${""}`}
                          >
                            Add To Cart
                  </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
              {//<div align="center">
               // <h4>{isFetching && isOver && "Loading..."}</h4>
              //</div>
}
            </div>
            {//<Scrolling />
}
          </Col>

        </Row>
      </Container>
    </div>
  );
}
